import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../context/AppContext";
import searchicon from "../../assets/images/search_icon.svg";
import ApiConfig from "../../api/ApiConfig";
import {simpleGetCallWithErrorResponse } from "../../api/ApiServices";
import { ToastMsg } from "../TostMsg";
import Loader from "../Loader";
import NoData from "../../sharedComponent/NoData";
import Pagination from "../../sharedComponent/Pagination";

const DefineWorkFlow = () => {
  const { sidebar } = useContext(AppContext);
 

  const [subCriptionList, setSubcriptionList] = useState([])
  const [loading, setLoding] = useState(false)

  const [filterSerchName, setFilterSerchName] = useState([])
  const [sortOrder, setSortOrder] = useState("asc");
  const [usersPerPage, setUsersPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const startIndex = currentPage * usersPerPage;
  const endIndex = startIndex + parseInt(usersPerPage);



  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected);
  };
 

  const getSubcriptionList = () => {
    const getUserId = localStorage.getItem("UserId")
    setLoding(true)
    simpleGetCallWithErrorResponse(ApiConfig.GET_WORKFLOW_DESHBOARD+getUserId).then((res) => {
      setLoding(false)

      if (res?.json?.success) {
        setSubcriptionList(res?.json?.data)
      }

    })


  }

 




  useEffect(() => {
    getSubcriptionList();
  }, [])



  useEffect(() => {
    const filteredData = subCriptionList?.definedWorkFlowLists?.filter((item) => {
      const searchLowers = searchValue.toLowerCase();
      const matchesSearch = (item?.name && item?.name.toLowerCase().includes(searchLowers)) ||
        (item?.taskName && item?.taskName.toLowerCase().includes(searchLowers));
      return matchesSearch 
    });
    setFilterSerchName(filteredData);
  }, [subCriptionList?.definedWorkFlowLists, searchValue, ]);

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="sub-header-role">
          <h6 className="mt-2 role-text">Defined Workflows</h6>
     
        </div>
        <div className="d-flex align-items-center">
          <div className="search-input-wrapper my-auto mt-4">
            <input type="text"
              placeholder="Search"
              onChange={(e) => {
                setSearchValue(e.target.value);
                setCurrentPage(0);
              }}
            />
            <img src={searchicon} alt="" />
          </div>
 
          
        </div>
        <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
          <table>
            <tr>
              <th>Sr. No.</th>
              
              <th>Workflow Name</th>
              {/* <th>	Step Name</th> */}
              <th
             style={{ cursor: "pointer" }}  
              >Status</th>
              
            </tr>{
              loading ? (
                <tr>
                  <td colSpan="9" className="text-center">
                    <Loader />
                  </td>
                </tr>
              ) : (
                filterSerchName?.length > 0 ? (
                  filterSerchName.slice(startIndex, endIndex).map((item, index) => (
                    <tr className="table-row-custom">
                      <td>{index + 1}</td>
                      <td>{item?.name}</td>
                      <td> {item.status === "Suspended" ? (
                  <span className="colorP">{item.status}</span>
                ) : item.status === "Allocated" ? (
                  <span className="colorAl">{item.status}</span>
                ) : item.status === "Completed" ? (
                  <span className="colorC">{item.status}</span>
                ) : item.status === "Deallocated" ? (
                  <span className="colorDe">{item.status}</span>
                ) : item.status === "Abort" ? (
                  <span className="colorAb">{item.status}</span>
                ) : item.status === "InProgress" ? (
                  <span className="colorIn">{item.status}</span>
                ) : item.status === "Rejected" ? (
                  <span className="colorR">{item.status}</span>
                ) : item.status === "Reallocated" ? (
                  <span className="colorRe">{item.status}</span>
                ) : item.status === "Hold" ? (
                  <span className="colorH">{item.status}</span>
                ) : item.status === "Approved" ? (
                  <span className="colorA"></span>
                ) : (
                  ""
                )}
                </td>
                     

                     
                    </tr>
                  ))

                ) : (
                  <tr className="ErrorDataNotFound">
                    <td colSpan={9}>
                      <NoData />
                    </td>
                  </tr>
                )
              )
            }
          </table>
        </div>
        <Pagination
          data={subCriptionList?.definedWorkFlowLists}
          pageChangeHandler={pageChangeHandler}
          usersPerPage={usersPerPage}
          currentPage={currentPage}
          searchValue={searchValue}
          searchedDataPageCounts={filterSerchName}
        />
      </div>

    </>
  );
}

export default DefineWorkFlow