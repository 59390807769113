import React, { useContext } from 'react'
import { AppContext } from '../context/AppContext';

export default function ReplyTo({ replyTo, msgRef }) {
    const { conversations, newMessages } = useContext(AppContext)
    let msgIndex = conversations.map(item=> item.Team_Message_Id).indexOf(replyTo.Team_Message_Id)
    let reference = msgIndex !== -1 ? msgRef.current[msgIndex] : msgRef[conversations.length + newMessages.indexOf(replyTo)]
    return (
        <div className={"replyTo"}>
            <div className='message-main'>
                <div className='receive-msg'>
                    <div className="right-receive-msg" onClick={()=> reference.scrollIntoView()} >
                        {replyTo && replyTo.Team_Message_Text || replyTo.message ?
                            <div className="receive-msg-main">
                                <p className="msg-text">{replyTo.Team_Message_Text || replyTo.message} </p>
                            </div>
                            : null
                        }
                    </div>
                </div>
                </div>
            </div>

            )
}

