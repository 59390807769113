import React, { useContext, useEffect, useState } from "react";
import "../../assets/css/Role.scss";
import searchicon from "../../assets/images/search_icon.svg";
import importicon from "../../assets/images/import.svg";
import exporticon from "../../assets/images/export.svg";
import editicon from "../../assets/images/bx_edit.svg";
import eye_icon from "../../assets/images/eye_icon.svg";
import close from "../../assets/images/close.svg";
import bankIcon from "../../assets/images/banklogo.svg";
import deleteicon from "../../assets/images/delete.svg";
import Modal from "react-bootstrap/Modal";
import { AppContext } from "../../context/AppContext";
import { useNavigate, useParams } from "react-router-dom";
import timeicon from "../../assets/images/timemanage.svg";
import { DeleteCallWithErrorResponseWithToken, PostCallWithErrorResponse, PostCallWithErrorResponseReqBody, simpleGetCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { validEmail } from "../ChatAdmin/commaon";
import { ToastMsg } from "../TostMsg";
import Loader from "../Loader";
import NoData from "../../sharedComponent/NoData";
import CommonButton from "../ChatAdmin/button";




const BranchList = () => {

  const branch = {
    "Id": 0,
    "EntityId": 0,
    "BranchName": "",
    "IsHeadOffice": false,
    "TANNumber": "",
    "TANTracesId": "",
    "TDSUserIdTrace": "",
    "TANEfilingId": "",
    "TDSUserIdTax": "",
    "TANEfilingPassword": "",
    "PTRCNumber": "",
    "PTRCId": "",
    "GSTNumber": "",
    "GSTLoginId": "",
    "GSTPassword": "",
    "GSTEwayBillId": "",
    "GSTEwayBill": "",
    "GSTEwayBillPassword": "",
    "GSTEmail": "",
    "GSTMobile": "",
    "Address1": "",
    "Address2": "",
    "Locality": "",
    "City": "",
    "State": "",
    "Country": "",
    "ZIP": "",
    "TANTracePassword": "",
    "PTRCPassword": "",
    "IsSuspended": false,
  }
  const { sidebar } = useContext(AppContext);

  const { entityId } = useParams()


  const [state, setState] = useState(branch)
  const [branchList, setBranchList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [deletId, setDeleteId] = useState('')
  const [isApiLoad, setIsApiLoad] = useState(false)


  const [show, setShow] = useState(false);
  const [conomation, setConomation] = useState(false);
  ;

  const handleShow = () => setShow(true);

  const handleClosed = () => {

    setShow(false)
    setIsValided(false)
    setState(branch)

  }

  const handeldelete = () => setConomation(true);
  const handeldeleteclose = () => setConomation(false);
  const navigate = useNavigate();

  const [isValiteded, setIsValided] = useState(false)


  const getBranchList = () => {

    setIsLoading(true)

    simpleGetCallWithErrorResponse(ApiConfig.GET_BRANCH_LIST + entityId).then((res) => {

      if (res?.json?.success) {

        setBranchList(res?.json?.data)

      }

      setIsLoading(false)

    })

  }

  useEffect(() => {

    getBranchList()

  }, [])


  const handleChange = (key, val) => {

    setState(prev => ({
      ...prev,
      [key]: val
    }))

  }


  const handleSubmit = () => {


    const keys = Object.keys(state)

    state.EntityId = Number(entityId)

    let isValid = true

    const notCheckKeys = ["Id",'IsHeadOffice','IsSuspended','Address2','TDSUserIdTrace','TDSUserIdTax','EntityId']

    keys.forEach((key) => {


      

     if (!notCheckKeys.includes(key)&&(!state[key] || !state[key]?.length)) {


        isValid = false

        setIsValided(true)

      }

      if (key === 'GSTEmail' && !validEmail(state[key])) {
        isValid = false
        setIsValided(true)

      }

      if (key === 'GSTMobile' && state.GSTMobile?.length != 10) {
        isValid = false

        setIsValided(true)
      }

    })

    console.log(state);

    if (isValid) {

      setIsApiLoad(true)

      const url = state.Id === 0 ? ApiConfig.CREATE_BRANCH : ApiConfig.UPDATE_BRANCH

      PostCallWithErrorResponse(url, JSON.stringify(state)).then((res) => {

        if (res?.json?.success) {

          ToastMsg('success', res?.json?.message)

          handleClosed()

          getBranchList()

        } else {

          ToastMsg('error', res?.json?.message)

        }

        setIsApiLoad(false)

      })
    }

    console.log(state);

  }


  const handleDeleteBranch = () => {

    PostCallWithErrorResponse(ApiConfig.DELETE_BRANCH + deletId).then((res) => {

      if (res?.json?.success) {

        ToastMsg('success', res?.json?.message)

        handeldeleteclose()

        getBranchList()


      } else {

        ToastMsg('error', res?.json?.message)

      }

    })

  }


  const getSingleBranch = (id) => {

    setShow(true)

    simpleGetCallWithErrorResponse(ApiConfig.GET_SINGLE_BRANCH + id).then((res) => {

      if (res?.json?.success) {

        let data = res?.json?.data


        setState({
          "Id": data?.id,
          "EntityId": data?.entityId,
          "BranchName": data?.branchName,
          "IsHeadOffice": data?.isHeadOffice,
          "TANNumber": data?.tanNumber,
          "TANTracesId": data?.tanTracesId,
          "TDSUserIdTrace": data?.tdsUserIdTrace,
          "TANEfilingId": data?.tanEfilingId,
          "TDSUserIdTax": data?.tdsUserIdTax,
          "TANEfilingPassword": data?.tanEfilingPassword,
          "PTRCNumber": data?.ptrcNumber,
          "PTRCId": data?.ptrcId,
          "GSTNumber": data?.gstNumber,
          "GSTLoginId": data?.gstLoginId,
          "GSTPassword": data?.gstPassword,
          "GSTEwayBillId": data?.gstEwayBillId,
          "GSTEwayBill": data?.gstEwayBill,
          "GSTEwayBillPassword": data?.gstEwayBillPassword,
          "GSTEmail": data?.gstEmail,
          "GSTMobile": data?.gstMobile,
          "Address1": data?.address1,
          "Address2": data?.address2,
          "Locality": data?.locality,
          "City": data?.city,
          "State": data?.state,
          "Country": data?.country,
          "ZIP": data?.zip,
          "TANTracePassword": data?.tanTracePassword,
          "PTRCPassword": data?.ptrcPassword,
          "IsSuspended": data?.isSuspended,
          
        })
      }

    })

  }


  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div
      >
        <div className="role-content">
          <div className="sub-header-role userList33">
            <h6 className="mt-2 role-text">
              <span
                className="me-1 bread-cumb-color"
                onClick={() => navigate("/clientlist")}
              >
                Client{" "}
              </span>
              <span className="me-1 back-slash"> /</span>
              <span
                className="me-1 bread-cumb-color"
                onClick={() => navigate("/entitieslist")}
              >
                Entities
              </span>
              <span className="me-1 back-slash"> /</span>
              Branch
            </h6>
            <button className="create-btn-sub-header" onClick={handleShow}>
              Create
            </button>
          </div>
          <div className="d-flex justify-content-between role-searchbar-and-btns resposiveheadder">
            <div className="role-table-header reponsivesrc">
              <div className="search-input-wrapper">
                <input type="text" placeholder="Search" />
                <img src={searchicon} alt="" />
              </div>
              <div className="entries-wrapper">
                <p>Entries per page</p>
                <select
                  class="form-select tasKCategory1 select-drop-icon remove-border"
                  aria-label="Default select example"
                >
                  <option selected>10</option>
                  <option value="1">20</option>
                  <option value="2">10</option>
                  <option value="3">5</option>
                </select>
              </div>
            </div>
            <div className="role-btns-wrapper">
              <button>
                <img src={importicon} alt="" />
              </button>
              <button>
                {" "}
                <img src={exporticon} alt="" />
              </button>
            </div>
          </div>
          <div className="table-wrapper">
            <table>
              <tr>
                <th>Sr. no</th>
                <th>Branch Name</th>
                <th>Head Office</th>
                <th>TAN Number</th>
                <th>PTRC Number</th>
                <th>GST Number</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
              {
                isLoading ? <Loader /> : branchList.length === 0 ? <NoData /> :
                  branchList.map((branc, index) => {

                    return <tr className="table-row-custom">
                      <td>{index + 1}</td>
                      <td>{branc?.branchName}</td>
                      <td>{branc?.isHeadOffice ? 'Yes' : 'No'}</td>
                      <td>{branc?.tanNumber}</td>
                      <td>{branc?.ptrcNumber}</td>
                      <td>{branc?.gstNumber}</td>
                      <td className="green-active">Active</td>
                      <td>
                        <img
                          src={editicon}
                          alt=""
                          className="mx-2"
                          onClick={() => getSingleBranch(branc.id)}
                        />
                        <img
                          src={deleteicon}
                          alt="Pri"
                          className="mx-2"
                          onClick={() => {
                            setDeleteId(branc.id)
                            handeldelete()
                          }}
                        />
                        <img
                          src={bankIcon}
                          alt="Pri"
                          className="mx-2"
                          onClick={() => navigate("/bank_list/" + branc.id)}
                        />
                        <img
                          src={timeicon}
                          alt="Pri"
                          className="mx-2"
                          onClick={() => navigate("/servicemapping/" + branc.id)}
                        />
                      </td>
                    </tr>

                  })
              }
            </table>
          </div>
        </div>
      </div>

      {/* <<<<<<...................modal create starts here..........................>>>> */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        size="xl"
        className="create-workflow-main modal-lg"
      >
        <Modal.Header>
          <Modal.Title>
            <label htmlFor="" className="create-role-text">
              {state?.Id == 0 ? "Create" : "Update"} Branch{" "}
            </label>
          </Modal.Title>
          <img src={close} alt="" onClick={handleClosed} />
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Body style= {{ maxHeight: '580px', overflowX: 'scroll', scrollbarWidth: 'none' }}>
          <div>
            <p className="create-role-text mb-0">Branch</p>
          </div>
          <div className="row mb-5">
            <div className="col-lg-3 mt-3">
              <label htmlFor="" className="text-grey">
                Branch Name
              </label>
              <input type="text" className="form-control tasKCategory1"
                value={state.BranchName}
                onChange={(e) => handleChange('BranchName', e.target.value)}
              />
              <p className="text-danger">{isValiteded && !state?.BranchName?.length && 'Please Enter branch Name'}</p>
            </div>
            <div className="col-lg-3 mt-3">
              <label htmlFor="" className="text-grey">
                Is HO
              </label>
              <select
                class="form-select tasKCategory1 select-drop-icon"
                aria-label="Default select example"
                value={state.IsHeadOffice}
                onChange={(e) => handleChange('IsHeadOffice', e.target.value === 'true')}
              >
                <option selected value={''}>Select Is Office Or Not </option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </select>
            </div>
          </div>
          <div>
            <p className="create-role-text mb-0">TAN</p>
          </div>
          <div className="row mb-5">
            <div className="col-lg-3 mt-3">
              <label htmlFor="" className="text-grey">
                TAN Number
              </label>
              <input type="number" className="form-control tasKCategory1"
                value={state.TANNumber}
                onChange={(e) => handleChange('TANNumber', e.target.value)}
              />
              <p className="text-danger">{isValiteded && !state?.TANNumber?.length && 'Please Enter Tan Number'}</p>
            </div>
            <div className="col-lg-3 mt-3">
              <label htmlFor="" className="text-grey">
                TAN Trace ID
              </label>
              <input type="text" className="form-control tasKCategory1"
                value={state.TANTracesId}
                onChange={(e) => handleChange('TANTracesId', e.target.value)}
              />
              <p className="text-danger">{isValiteded && !state?.TANTracesId?.length && 'Please Enter Tan Trace Id'}</p>
            </div>
            <div className="col-lg-3 mt-3">
              <label htmlFor="" className="text-grey">
                TAN Trace Password
              </label>
              <input type="text" className="form-control tasKCategory1"
                value={state.TANTracePassword}
                onChange={(e) => handleChange('TANTracePassword', e.target.value)} />
              <p className="text-danger">{isValiteded && !state?.TANTracePassword?.length && 'Please Enter Tan Trace Password '}</p>
            </div>
            <div className="col-lg-3 mt-3">
              <label htmlFor="" className="text-grey">
                TAN E-filling ID
              </label>
              <input type="text" className="form-control tasKCategory1"
                value={state.TANEfilingId}
                onChange={(e) => handleChange('TANEfilingId', e.target.value)} />
              <p className="text-danger">{isValiteded && !state?.TANEfilingId?.length && 'Please Enter Trance E-Filled Id'}</p>
            </div>
            <div className="col-lg-3 mt-3">
              <label htmlFor="" className="text-grey">
                TAN E-filling Password
              </label>
              <input type="text" className="form-control tasKCategory1"
                value={state.TANEfilingPassword}
                onChange={(e) => handleChange('TANEfilingPassword', e.target.value)} />
              <p className="text-danger">{isValiteded && !state?.TANEfilingPassword?.length && 'Please Enter Trance E-filling Password'}</p>
            </div>
          </div>
          <div>
            <p className="create-role-text mb-0">PTRC</p>
          </div>
          <div className="row mb-5">
            <div className="col-lg-3 mt-3">
              <label htmlFor="" className="text-grey">
                PTRC Number
              </label>
              <input type="text" className="form-control tasKCategory1"
                value={state.PTRCNumber}
                onChange={(e) => handleChange('PTRCNumber', e.target.value)} />
              <p className="text-danger">{isValiteded && !state?.PTRCNumber?.length && 'Please Enter PTRCNumber '}</p>
            </div>
            <div className="col-lg-3 mt-3">
              <label htmlFor="" className="text-grey">
                PTRC ID
              </label>
              <input type="text" className="form-control tasKCategory1"
                value={state.PTRCId}
                onChange={(e) => handleChange('PTRCId', e.target.value)} />
              <p className="text-danger">{isValiteded && !state?.PTRCId?.length && 'Please Enter PTCR Id '}</p>
            </div>
            <div className="col-lg-3 mt-3">
              <label htmlFor="" className="text-grey">
                PTRC Password
              </label>
              <input type="text" className="form-control tasKCategory1"
                value={state.PTRCPassword}
                onChange={(e) => handleChange('PTRCPassword', e.target.value)} />
              <p className="text-danger">{isValiteded && !state?.PTRCPassword?.length && 'Please Enter PTCR Password '}</p>
            </div>
          </div>
          <div>
            <p className="create-role-text mb-0">GST</p>
          </div>
          <div className="row mb-5">
            <div className="col-lg-3 mt-3">
              <label htmlFor="" className="text-grey">
                GST Number
              </label>
              <input type="text" className="form-control tasKCategory1"
                value={state.GSTNumber}
                onChange={(e) => handleChange('GSTNumber', e.target.value)} />
              <p className="text-danger">{isValiteded && !state?.GSTNumber?.length && 'Please Enter GSTNUMBER'}</p>
            </div>
            <div className="col-lg-3 mt-3">
              <label htmlFor="" className="text-grey">
                GST Login ID
              </label>
              <input type="text" className="form-control tasKCategory1"
                value={state.GSTLoginId}
                onChange={(e) => handleChange('GSTLoginId', e.target.value)} />
              <p className="text-danger">{isValiteded && !state?.GSTLoginId?.length && 'Please Enter GST Login Id '}</p>
            </div>
            <div className="col-lg-3 mt-3">
              <label htmlFor="" className="text-grey">
                GST Password
              </label>
              <input type="text" className="form-control tasKCategory1"
                value={state.GSTPassword}
                onChange={(e) => handleChange('GSTPassword', e.target.value)} />
              <p className="text-danger">{isValiteded && !state?.GSTPassword?.length && 'Please Enter GST Password'}</p>
            </div>
            <div className="col-lg-3 mt-3">
              <label htmlFor="" className="text-grey">
                GST E-Way Bill ID
              </label>
              <input type="text" className="form-control tasKCategory1"
                value={state.GSTEwayBillId}
                onChange={(e) => handleChange('GSTEwayBillId', e.target.value)} />
              <p className="text-danger">{isValiteded && !state?.GSTEwayBillId?.length && 'Please Enter GST E-Way Bill Id'}</p>
            </div>
            <div className="col-lg-3 mt-3">
              <label htmlFor="" className="text-grey">
                GST E-Way Bill
              </label>
              <input type="text" className="form-control tasKCategory1"
                value={state.GSTEwayBill}
                onChange={(e) => handleChange('GSTEwayBill', e.target.value)} />
              <p className="text-danger">{isValiteded && !state?.GSTEwayBill?.length && 'Please Enter GST E-Way Bill'}</p>
            </div>
            <div className="col-lg-3 mt-3">
              <label htmlFor="" className="text-grey">
                Password
              </label>
              <input type="text" className="form-control tasKCategory1"
                value={state.GSTEwayBillPassword}
                onChange={(e) => handleChange('GSTEwayBillPassword', e.target.value)} />
              <p className="text-danger">{isValiteded && !state?.GSTEwayBillPassword?.length && 'Please Enter GST E-Way Bill Password'}</p>
            </div>
            <div className="col-lg-3 mt-3">
              <label htmlFor="" className="text-grey">
                GST Mobile Number
              </label>
              <input type="number" className="form-control tasKCategory1"
                value={state.GSTMobile}
                onChange={(e) => handleChange('GSTMobile', e.target.value)} />
              <p className="text-danger">{isValiteded && state?.GSTMobile?.length != 10 && 'Please Enter GST Mobile No '}</p>
            </div>
            <div className="col-lg-3 mt-3">
              <label htmlFor="" className="text-grey">
                GST Email ID
              </label>
              <input type="email" className="form-control tasKCategory1"
                value={state.GSTEmail}
                onChange={(e) => handleChange('GSTEmail', e.target.value)} />
              <p className="text-danger">{isValiteded && !validEmail(state.GSTEmail) && 'Please Enter Valid Email'}</p>
            </div>
            <div className="col-lg-3 mt-3">
              <label htmlFor="" className="text-grey">
                Is Suspended
              </label>
              <select
                class="form-select tasKCategory1 select-drop-icon"
                aria-label="Default select example"
                value={state.IsSuspended}
                onChange={(e) => handleChange('IsSuspended', e.target.value == 'true')}
              >
                <option selected value={''}>Select Is Suspended</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </select>
            </div>
          </div>
          <div>
            <p className="create-role-text mb-0">Address</p>
          </div>
          <div className="row mb-5">
            <div className="col-lg-6 mt-3">
              <label htmlFor="" className="text-grey">
                Address 1
              </label>
              <input type="text" className="form-control tasKCategory1 "

                value={state.Address1}
                onChange={(e) => handleChange('Address1', e.target.value)} />
              <p className="text-danger">{isValiteded && !state?.Address1?.length && 'Please Enter Address'}</p>
            </div>
            <div className="col-lg-6 mt-3">
              <label htmlFor="" className="text-grey">
                Address 2 (optional)
              </label>
              <input type="text" className="form-control tasKCategory1 "
                value={state.Address2}
                onChange={(e) => handleChange('Address2', e.target.value)} />
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="col-lg-2 mt-5">
                <label htmlFor="" className="text-grey">
                  Locality
                </label>
                <input type="text" className="form-control tasKCategory1"
                  value={state.Locality}
                  onChange={(e) => handleChange('Locality', e.target.value)} />

                <p className="text-danger">{isValiteded && !state?.Locality?.length && 'Please Enter Locality Name'}</p>
              </div>
              <div className="col-lg-2 mt-5">
                <label htmlFor="" className="text-grey">
                  City
                </label>
                <input type="text" className="form-control tasKCategory1"
                  value={state.City}
                  onChange={(e) => handleChange('City', e.target.value)} />
                <p className="text-danger">{isValiteded && !state?.City?.length && 'Please Enter City Name'}</p>
              </div>
              <div className="col-lg-2 mt-5">
                <label htmlFor="" className="text-grey">
                  State
                </label>
                <input type="text" className="form-control tasKCategory1 "
                  value={state.State}
                  onChange={(e) => handleChange('State', e.target.value)} />
                <p className="text-danger">{isValiteded && !state?.State?.length && 'Please Enter State Name'}</p>
              </div>
              <div className="col-lg-2 mt-5">
                <label htmlFor="" className="text-grey">
                  Country
                </label>
                <input type="text" className="form-control tasKCategory1"
                  value={state.Country}
                  onChange={(e) => handleChange('Country', e.target.value)} />
                <p className="text-danger">{isValiteded && !state?.Country?.length && 'Please Enter Country Name'}</p>
              </div>
              <div className="col-lg-2 mt-5">
                <label htmlFor="" className="text-grey">
                  ZIP/Pin Code
                </label>
                <input type="text" className="form-control tasKCategory1"
                  value={state.ZIP}
                  onChange={(e) => handleChange('ZIP', e.target.value)} />
                <p className="text-danger">{isValiteded && (!state?.ZIP || !state?.ZIP?.length) && 'Please Enter ZIP Code '}</p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <div className="hr-line-modal-1">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Footer>
          <div>
            <button className="modal-cancel-btn" onClick={handleClosed}>
              Cancel
            </button>
            <CommonButton
              title={state?.Id == 0 ? "Save" : "Update"}
              isLoading={isApiLoad}
              handlerFunction={handleSubmit}
            />
          </div>

        </Modal.Footer>
      </Modal>


      {/* delete model */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={conomation}
        className="create-workflow-main modal-sm"
      >
        <Modal.Header>
          <Modal.Title>
            <label htmlFor="" className="create-workflow-text">
              Delete Branch
            </label>
          </Modal.Title>
          <img src={close} alt="" onClick={handeldeleteclose} />
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>

        <Modal.Body className="text-center">
          Are you sure you want to delete this branch ?
        </Modal.Body>
        {/* <div className="hr-line-modal-1">
          <hr style={{ margin: "0" }} />
        </div> */}
        {/* <Modal.Footer> */}
        <div className="text-center " style={{ padding: "15px" }}>
          <button className="modal-cancel-btn" onClick={handeldeleteclose}>
            Cancel
          </button>
          <button className="modal-create-btn"
            onClick={handleDeleteBranch}
          >Ok</button>
        </div>
        {/* </Modal.Footer> */}
      </Modal>
    </div>
  );
};

export default BranchList;
