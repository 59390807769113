import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";

const ProgressCircles = ({ timesheetCounts, timesheetCounts2, timesheetCounts4 }) => {

  const { permissions } = useContext(AppContext)
  //   {
  //     "Total": 9,
  //     "Approval": 9,
  //     "Pending": 0,
  //     "Reject": 0
  // }

  // const percentage = 75;
  // const percentage1 = 60;
  // const percentage2 = 80;
  // const percentage3 = 100;


  const percentage = timesheetCounts?.workFlowDefined ? timesheetCounts.workFlowDefined : 0;
  const percentage1 = timesheetCounts?.workFlowExecuted ? timesheetCounts.workFlowExecuted : 0;
  const percentage2 = timesheetCounts?.workFlowCompleted ? timesheetCounts.workFlowCompleted : 0;
  // const percentage3 = timesheetCounts?.Pending ? timesheetCounts.Pending : 0;
  // const percentage4 = timesheetCounts4?.totalWorkingHours ? timesheetCounts4.totalWorkingHours : 0;
  // const percentage5 = timesheetCounts4?.totalTimesheetWorkHours ? timesheetCounts4.totalTimesheetWorkHours : 0;







  return (
    <>
      <div className="timesheet-progress-wrapper">
        <div >
          <h5 className="mt-2">Planning</h5>
          
        </div>

        <div className="progress-circles-sections">
          <div className="row align-items-center text-center">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4">
              <Link 
              to="/workFlowList"
              >
                <div className="progress-circles">
                  <div className="circles "
                  >
                    <CircularProgressbar
                      value={percentage == 0 ? 0 : 100}
                      text={`${percentage}`}
                      styles={buildStyles({
                        rotation: 0,
                        strokeLinecap: "butt",
                        textSize: "16px",
                        pathTransitionDuration: 0.5,
                        pathColor: `#53B7E8`,
                        textColor: "#000",
                        trailColor: "#d6d6d6",
                        backgroundColor: "#3e98c7",
                      })}
                    />
                  </div>
                  <div className="progress-labels pt-2">
                    <p>
                    Defined  <br /> Workflow
                    </p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4">
              <Link
               to="/executionList"
               >
                <div className="progress-circles">
                  <div className="circles">
                    <CircularProgressbar
                      value={percentage1 == 0 ? 0 : (percentage1 / percentage) * 100}
                      text={`${percentage1}`}
                      styles={buildStyles({
                        rotation: 0,
                        strokeLinecap: "butt",
                        textSize: "16px",
                        pathTransitionDuration: 0.5,
                        pathColor: `#FFA903`,
                        textColor: "#000",
                        trailColor: "#d6d6d6",
                        backgroundColor: "#3e98c7",
                      })}
                    />
                  </div>
                  <div className="progress-labels">
                    <p>
                    Executed  <br /> Workflow
                    </p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4">
              <Link to="/completedworkflows">
                <div className="progress-circles">
                  <div className="circles">
                    <CircularProgressbar
                      value={percentage2 == 0 ? 0 : (percentage2 / percentage) * 100}
                      text={`${percentage2}`}
                      styles={buildStyles({
                        rotation: 0,
                        strokeLinecap: "butt",
                        textSize: "16px",
                        pathTransitionDuration: 0.5,
                        pathColor: `#00D823`,
                        textColor: "#000",
                        trailColor: "#d6d6d6",
                        backgroundColor: "#3e98c7",
                      })}
                    />
                  </div>
                  <div className="progress-labels ">
                    <p>
                    Completed  <br /> Workflow
                    </p>
                  </div>
                </div>
              </Link>
            </div>
            {/* <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4">
              <Link to="/timesheet">
                <div className="progress-circles">
                  <div className="circles">
                    <CircularProgressbar
                      value={percentage3 == 0 ? 0 : (percentage3 / percentage) * 100}
                      text={`${percentage3}`}
                      styles={buildStyles({
                        rotation: 0,
                        strokeLinecap: "butt",
                        textSize: "16px",
                        pathTransitionDuration: 0.5,
                        pathColor: `#11111199`,
                        textColor: "#000",
                        trailColor: "#d6d6d6",
                        backgroundColor: "#3e98c7",
                      })}
                    />
                  </div>
                  <div className="progress-labels">
                    <p>
                      Timesheet For
                      <br /> Approval
                    </p>
                  </div>
                </div>
              </Link>
            </div> */}
            {/* <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4">
              <Link to="/timesheethistory">
                <div className="progress-circles">
                  <div className="circles">
                    <CircularProgressbar
                      value={percentage4 ? 100 : 0}
                      text={`${percentage4}`}
                      styles={buildStyles({
                        rotation: 0,
                        strokeLinecap: "butt",
                        textSize: "16px",
                        pathTransitionDuration: 0.5,
                        pathColor: `#FF0000`,
                        textColor: "#000",
                        trailColor: "#d6d6d6",
                        backgroundColor: "#3e98c7",
                      })}
                    />
                  </div>
                  <div className="progress-labels">
                    <p>
                      Total <br /> Working Hrs
                    </p>
                  </div>
                </div>
              </Link>
            </div> */}
            {/* <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4">
              <Link to="/timesheet">
                <div className="progress-circles">
                  <div className="circles">
                    <CircularProgressbar
                      value={percentage5 == 0 ? 0 : (percentage5 / percentage4) * 100}
                      text={`${percentage5}`}
                      styles={buildStyles({
                        rotation: 0,
                        strokeLinecap: "butt",
                        textSize: "16px",
                        pathTransitionDuration: 0.5,
                        pathColor: `#4CAF50`,
                        textColor: "#000",
                        trailColor: "#d6d6d6",
                        backgroundColor: "#3e98c7",
                      })}
                    />
                  </div>
                  <div className="progress-labels">
                    <p>
                      Timesheet
                      <br /> Filled Hrs
                    </p>
                  </div>
                </div>
              </Link>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProgressCircles;
