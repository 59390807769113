import React, { useContext, useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import searchicon from "../../assets/images/search_icon copy.svg";
import importicon from "../../assets/images/import.svg";
import exporticon from "../../assets/images/export.svg";
import editicon from "../../assets/images/bx_edit.svg";
import building from "../../assets/images/building.svg";
import popup from "../../assets/images/popup-link-icon.svg";
import righticon from "../../assets/images/right.svg";
import close from "../../assets/images/close.svg";
import Pagination from "../ChatAdmin/Pagination";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { validEmail } from "../ChatAdmin/commaon";
import { PostCallWithErrorResponse, simpleGetCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { ToastMsg } from "../TostMsg";
import Loader from "../Loader";
import NoData from "../../sharedComponent/NoData";
import CommonButton from "../ChatAdmin/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { Accordion } from "react-bootstrap";
function ClientList() {
  const { sidebar, userData } = useContext(AppContext);
  const [pageSize, setPageSize] = useState(25)
  const [currentPage, setCuurentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [isApiLoad, setIsLoad] = useState(false)
  const [show1, setShow1] = useState(false);
  const [checkStatus, SetCheckStatus] = useState(true)
  const [searchFields, setSearchFields] = useState("")
  const [filterSearchName, setFilterSearchName] = useState([])


  const clientDetails = {
    Id: 0,
    "Title": "",
    "FirstName": "",
    "MiddleName": "",
    "LastName": "",
    "Email": "",
    "Phone": "",
    noOtp: '',
    emailOtp: ''
  }


  const dataList = [
    {
      operationGroup: "Control Room",
      section: "Feed",
      serial: "1",
      items: [
        { section: "Residue & Steam", serial: "2" },
        { section: "G.O Pump Agound", serial: "3" },
        { section: "Column Top DA 3001 & DA 3002", serial: "4" },
        { section: "Naptha Stabilizer", serial: "5" },
        { section: "BA 3001 AND FLUSH OIL", serial: "6" },
      ],
    },

    {
      operationGroup: "Control Room -1",
      section: "Feed -1",
      serial: "2",
      items: [
        { section: "Residue & Steam -1", serial: "2" },
        { section: "G.O Pump Agound", serial: "3" },
        { section: "Column Top DA 3001 & DA 3002", serial: "4" },
        { section: "Naptha Stabilizer", serial: "5" },
        { section: "BA 3001 AND FLUSH OIL", serial: "6" },
      ],
    },
  ];
  const navigate = useNavigate()

  const [cliendDet, setCliientDet] = useState(clientDetails)
  const [clientList, setClientList] = useState([])
  const [clientQationList, setClientQationList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isFiiledDetails, setIsFilledDetails] = useState(false)

  const [validated, setValidated] = useState(false);

  const [show, setShow] = useState(false);
  const [ShowPopop, setShowPopop] = useState(false);
  const clientId = useRef()
  const [expanded, setExpanded] = useState(false);


  const toggleExpand = () => setExpanded(!expanded);
  const handleShow = () => setShow(true);
  const handleShowList = (id) => {
    setShowPopop(true)
    getQationList(id)
  };
  const handleCloseList = () => setShowPopop(false);
  const handleClose = () => {
    setValidated(false)
    setCliientDet(clientDetails)
    setIsFilledDetails(false)
    setShow(false)
  }

  const handleShowActive = (item) => {
    clientId.current = item
    setShow1(true)
  }
  const handleCloses = () => {
    setShow1(false)
  };





  const handleChange = (key, val) => {

    setCliientDet(prev => ({
      ...prev,
      [key]: val
    }))

  }

  const getClientList = (page) => {
    if (page > 1) {
      setIsLoading(false)
    } else {
      setIsLoading(true)
    }


    simpleGetCallWithErrorResponse(ApiConfig.GET_CLIENTS + `currentPage=${currentPage}&pageSize=${pageSize}&searchField=${searchFields}&searchValue=&Active=${checkStatus}`).then((res) => {


      if (res?.json?.success) {

        setTotalPage(res?.json?.pageCount)

        setClientList(res?.json?.data)

      }

      setIsLoading(false)

    })

  }




  const getQationList = (id) => {

   

    simpleGetCallWithErrorResponse(ApiConfig.GETQUOTATIONLIST + id).then((res) => {


      if (res?.json?.success) {

    

        setClientQationList(res?.json?.data)

      }

 

    })

  }





  useEffect(() => {

    getClientList(currentPage)

  }, [currentPage, pageSize, checkStatus])

  const verifyOtp = async (isEmail, isEdit, otp) => {

    let body = {
      "Otp": otp,
      "IsToVerify": true,
      "IsToVerifyClient": true,
      "IsToEditClient": false,
      "IsEmail": isEmail,
    }

    isEmail ? body.Email = cliendDet.Email : body.Phone = cliendDet.Phone

    let res = await PostCallWithErrorResponse(ApiConfig.VERIFY_OTP_WORKFLOW, JSON.stringify(body))



    return res?.json?.success

  }

  const handleVerification = async () => {

    if (cliendDet?.noOtp && cliendDet?.emailOtp) {
      let noOtp = await verifyOtp(false, false, cliendDet?.noOtp)


      let emailVer = await verifyOtp(true, false, cliendDet?.emailOtp)





      if (emailVer && noOtp) {

        ToastMsg('success', 'Verification Successfully Done')
        handleClose()
        setCliientDet(clientDetails)
        setIsFilledDetails(false)

      } else {
        !emailVer && ToastMsg('error', 'InValid Otp For email')
        !noOtp && ToastMsg('error', 'InValid Otp For No')
      }

    } else {
      setValidated(true)
    }


  }

  const handleCreate = () => {

    let keys = Object.keys(cliendDet)

    let isValid = true

    keys.forEach((key) => {


      if ((key != "Id" && key != 'UserId' && key != 'emailOtp' && key != 'noOtp') && !cliendDet[key]) {


        isValid = false

        console.log(1, key);
        setValidated(true)

      }

      if (key === 'Email' && !validEmail(cliendDet[key])) {

        isValid = false


        setValidated(true)

      }

      if (key == "Phone" && cliendDet[key].length != 10) {

        isValid = false
        console.log(3);

        setValidated(true)

      }

    })

    if (isValid) {




      setIsLoad(true)

      let body = cliendDet

      delete body.emailOtp
      delete body.noOtp


      body.UserId = Number(userData.UserId)
      console.log(cliendDet);

      let url = cliendDet.Id === 0 ? ApiConfig.REGISTER_CLIENT : ApiConfig.UPDATE_CLIENT

      PostCallWithErrorResponse(url, JSON.stringify(body)).then((res) => {


        if (res?.json?.success) {
          ToastMsg('success', res?.json?.message)

          getClientList()

          setIsFilledDetails(true)
          setIsLoad(false)
        } else {
          ToastMsg('error', res?.json?.message)

          setIsLoad(false)
        }


      })


    }


  }


  const onActiveSubscription = () => {
    setIsLoad(true);
    const id = clientId.current?.id
    simpleGetCallWithErrorResponse(ApiConfig?.CLIENT_ACTIVE_DEACTIVE + `${id}`)
      .then((res) => {
        if (res?.json?.success === true) {
          ToastMsg("success", res?.json?.message);
          setIsLoad(false);
          setShow1(false)
          getClientList()
        } else {
          ToastMsg("error", res?.json?.message);
        }
        console.log("update Data>>>", res)
        setIsLoad(false)
      })
      .catch((err) => {
        console.log("update Data Error >>>", err)
      })
  }


  useEffect(() => {

    const filterData = clientList?.filter((item) => {
      const searchData = searchFields?.toLocaleLowerCase()

      return (
        item?.title?.toLowerCase().includes(searchData) ||
        item?.firstName?.toLowerCase().includes(searchData) ||
        item?.middleName?.toLowerCase().includes(searchData) ||
        item?.lastName?.toLowerCase().includes(searchData) ||
        item?.email?.toLowerCase().includes(searchData)

      );

    })
    setFilterSearchName(filterData)
  }, [clientList, searchFields])



const removeStep = (index, id) => {
  // state?.tasks?.splice(index, 1);


};

  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div className="role-content">
        <div className="sub-header-role userList33">
          <h6 className="mt-2 role-text">Client </h6>
          <div>
            <button
              className="create-btn-sub-header"
              style={{ marginRight: "0" }}
              onClick={handleShow}
            >
              Create
            </button>
          </div>
        </div>
        <div className="d-flex justify-content-between role-searchbar-and-btns resposiveheadder">
          <div className="role-table-header reponsivesrc">
            <div className="search-input-wrapper">
              <input type="text"
                placeholder="Search"
                onChange={(e) => setSearchFields(e.target.value)}
              />
              <img src={searchicon} alt="" />
            </div>
            <div className="entries-wrapper">
              <p>Entries per page</p>
              <select
                class="form-select select-drop-icon remove-border"
                aria-label="Default select example"
                value={pageSize}
                onChange={(e) => setPageSize(e.target.value)}
              >
                <option >10</option>
                <option value="25" selected>25</option>
                <option value="50">50</option>
                <option value="75">75</option>
              </select>
            </div>
            <div className="entries-wrapper">
              <p>Entries Status</p>
              <select
                class="form-select select-drop-icon remove-border"
                aria-label="Default select example"
                value={checkStatus}
                onChange={(e) => {
                  SetCheckStatus(e.target.value);
                  setCuurentPage(1)

                }}
              >
                <option value={""} selected>Select</option>
                <option value={true} >Active</option>
                <option value={false}>De-Active</option>
              </select>
            </div>
          </div>
          <div className="role-btns-wrapper">
            <button>
              <img src={importicon} alt="" />
            </button>
            <button className="lastchalidbtn">
              {" "}
              <img src={exporticon} alt="" />
            </button>
          </div>
        </div>

        <div className="table-wrapper">
          <table>
            <tr>
              <th>Sr. no</th>
              <th>Title</th>
              <th>First Name</th>
              <th>Middle Name</th>
              <th>Last Name</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Category</th>
              <th>Status</th>
              <th className="mx-3">Action</th>
            </tr>
            {isLoading ?
              <Loader />
              : clientList?.length == 0 ? <NoData /> : filterSearchName.map((client, index) => {
                return (
                  <tr className="table-row-custom">
                    <td>{index + 1}</td>
                    <td>{client.title}</td>
                    <td>{client.firstName}</td>
                    <td>{client.middleName}</td>

                    <td>{client.lastName}</td>
                    <td>{client.phone}</td>
                    <td>{client.email}</td>
                    <td>{client.category}</td>
                    <td
                      className={
                        client.isActive === true
                          ? "status-green"
                          : "status-red"
                      }
                      onClick={() => {
                        handleShowActive(client)
                      }}
                    >
                      {client.isActive ? 'Active' : 'De-Active'}
                    </td>
                    <td>
                      <img
                        src={editicon}
                        alt=""
                        className="mx-3"
                        onClick={() => {
                          setCliientDet({
                            Id: client.id,
                            "Title": client.title,
                            "FirstName": client.firstName,
                            "MiddleName": client.middleName,
                            "LastName": client.lastName,
                            "Email": client.email,
                            "Phone": client.phone,
                            noOtp: '',
                            emailOtp: ''
                          })
                          handleShow()
                        }}
                      />
                      <img
                        src={building}
                        alt=""
                        onClick={() => navigate("/entitieslist/" + client.id)}
                      />
                      <img
                      src={popup}
                      height="15px"
                      width="15px"
                      className="mx-3"
                      alt=""
                      onClick={() => {
           
                      handleShowList(client.id)
                      }}
                 
                    />
                    </td>
                  </tr>
                );
              })}
          </table>
        </div>

        {/* <<<<<<...................modal create starts here..........................>>>> */}
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show}
          className="modal-xl"
        >
          <Modal.Header>
            <Modal.Title>
              <label htmlFor="" className="create-role-text">
                {cliendDet?.Id === 0 ? 'Create' : 'Update'} Client Profile
              </label>
            </Modal.Title>
            <img src={close} alt="" onClick={handleClose} />
          </Modal.Header>
          <div className="hr-line-modal">
            <hr style={{ margin: "0" }} />
          </div>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-3 mt-4">
                <label htmlFor="" className="text-grey">
                  Title
                </label>
                <select
                  class="form-select tasKCategory1 select-drop-icon"
                  aria-label="Default select example"
                  value={cliendDet.Title}
                  onChange={(e) => {
                    handleChange("Title", e.target.value)
                  }}
                >
                  <option selected>Select Title</option>
                  <option value="Mr">Mr</option>
                  <option value="Ms">Ms</option>
                  <option value="Mrs">Mrs</option>
                </select>
                <p className="text-danger">{validated && !cliendDet.Title.length && "Please Select Title"}</p>
              </div>
              <div className="col-lg-3 mt-4">
                <label htmlFor="" className="text-grey">
                  First Name
                </label>
                <input type="text" className="form-control tasKCategory1"
                  value={cliendDet.FirstName}
                  onChange={(e) => {
                    handleChange("FirstName", e.target.value)
                  }}
                />
                <p className="text-danger">{validated && !cliendDet.FirstName.length && "Please Enter First Name"}</p>
              </div>
              <div className="col-lg-3 mt-4">
                <label htmlFor="" className="text-grey">
                  Middle Name
                </label>
                <input type="text" className="form-control tasKCategory1"
                  value={cliendDet.MiddleName}
                  onChange={(e) => {
                    handleChange("MiddleName", e.target.value)
                  }} />
                <p className="text-danger">{validated && !cliendDet.MiddleName.length && "Please Enter Middle Name"}</p>
              </div>
              <div className="col-lg-3 mt-4">
                <label htmlFor="" className="label- text-grey">
                  Last Name
                </label>
                <input type="text" className="form-control tasKCategory1"
                  value={cliendDet.LastName}
                  onChange={(e) => {
                    handleChange("LastName", e.target.value)
                  }} />

                <p className="text-danger">{validated && !cliendDet.LastName.length && "Please Enter Last Name"}</p>
              </div>
              <div className="col-lg-3 position-relative mt-4">
                <div className="d-flex justify-content-between align-center">
                  <label htmlFor="" className="label- text-grey"

                  >
                    Mobile
                  </label>
                  <div>
                    <button className="send-otp-btn"
                    >Send OTP</button>
                  </div>
                </div>
                <input type="number" className="form-control tasKCategory1"
                  value={cliendDet.Phone}
                  onChange={(e) => {
                    handleChange("Phone", e.target.value)
                  }}
                />
                <p className="text-danger">{validated && cliendDet.Phone.length != 10 && "Please Enter Mobile No"}</p>
                <img
                  src={righticon}
                  alt=""
                  className="right-icon-img-modal"
                />
              </div>
              {
                isFiiledDetails &&

                <div className="col-lg-3 position-relative mt-4">
                  <label htmlFor="" className="label-text">
                    Mobile OTP Verification
                  </label>
                  <input type="text" className="form-control tasKCategory1"
                    value={cliendDet.noOtp}
                    onChange={(e) => handleChange('noOtp', e.target.value)}
                  />
                  <p className="text-danger">{validated && !cliendDet?.noOtp?.length && "Please Enter OTP"}</p>
                  <img
                    src={righticon}
                    alt=""
                    className="right-icon-img-modal"
                  />
                </div>
              }
              <div className="col-lg-3 position-relative mt-4">
                <div className="d-flex justify-content-between align-center">
                  <label htmlFor="" className="text-grey">
                    Email
                  </label>
                  <div>
                    <button className="send-otp-btn"

                    >Send OTP</button>
                  </div>
                </div>
                <input type="text" className="form-control tasKCategory1"
                  value={cliendDet.Email}
                  onChange={(e) => {
                    handleChange("Email", e.target.value)
                  }}
                />
                <p className="text-danger">{validated && !validEmail(cliendDet?.Email) && "Please Enter Email"}</p>
                <img
                  src={righticon}
                  alt=""
                  className="right-icon-img-modal"
                />
              </div>
              {
                isFiiledDetails &&

                <div className="col-lg-3 position-relative mt-4">
                  <label htmlFor="" className="label-text">
                    Email OTP Verification
                  </label>
                  <input type="text" className="form-control tasKCategory1"
                    value={cliendDet.emailOtp}
                    onChange={(e) => handleChange('emailOtp', e.target.value)} />
                  <p className="text-danger">{validated && !cliendDet?.emailOtp?.length && "Please Enter Email"}</p>
                  <img
                    src={righticon}
                    alt=""
                    className="right-icon-img-modal"
                  />
                </div>
              }
            </div>
          </Modal.Body>
          <div className="hr-line-modal-1">
            <hr style={{ margin: "0" }} />
          </div>
          <Modal.Footer>
            <div className="model-btn22">
              <button className="cancel-btn" onClick={handleClose}>
                Cancel
              </button>
              <CommonButton
                title={isFiiledDetails ? 'Verify' : "Save & Proceed"}
                isLoading={isApiLoad}
                handlerFunction={isFiiledDetails ? handleVerification : handleCreate}
              />
            </div>
          </Modal.Footer>
        </Modal>


      </div>
      <div className="row mt-4">
        <div className="col-lg-12">
          <Pagination
            totalPage={totalPage}
            currentPage={currentPage}
            pageChangeHandler={(page) => setCuurentPage(page + 1)}
          />
        </div>
      </div>


      <Modal
        className="delete-news-modal"
        show={show1} onHide={handleCloses}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{clientId.current?.isActive === true ? "De-Active Client  " : "Active Client"}</Modal.Title>
        </Modal.Header>
        <div class="lineSeperate"></div>
        <Modal.Body className="delete-body ps-4">
          <p>
            Are You Sure You Want To {clientId.current?.isActive ? "DeActive" : "Active"}{" "}
            <b>{clientId.current?.firstName} {clientId.current?.lastName}</b>
            {/* {"( "+deleteProjectName + " )"} */}
          </p>
        </Modal.Body>

        <div className="lineSeperate"></div>
        <div className="d-flex justify-content-end align-items-center mainBtnsSub">
          <button className="btn-width cancelBtn mx-3" onClick={handleCloses}>
            Cancel
          </button>
          <button
            className="btn-width saveBtn"
            onClick={() => {
              onActiveSubscription()
            }}
          >
            {clientId.current?.isActive === true ? "De Active" : "Active"}
          </button>
        </div>
      </Modal>

{/*  List Qoution  */} 

















             <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={ShowPopop}
        className="create-workflow-main modal-lg"
        size="xl"
        
      >
        <Modal.Header>
          <Modal.Title>
            <label htmlFor="" className="create-role-text">
            Quotation
            </label>
          </Modal.Title>
          <img src={close} alt="" onClick={handleCloseList} />
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Body>
          <div
            style={{
              maxHeight: "580px",
              overflowX: "scroll",
              scrollbarWidth: "none",
            }}
          >
     
        
     <div className="table-wrapper">
          <table>
            <tr>
              <th>Sr. no</th>
              <th>subscription Name</th>
              <th>Project Name</th>
              <th>Quotation Name</th>
              <th>start Date</th>
              <th>End Date</th>
              <th>Amount</th>
          
            
            </tr>
             
             {clientQationList &&
              clientQationList.length > 0 ? (
                clientQationList.map((client, index) => {
             return (
                <tr className="table-row-custom">
                  <td>{index + 1}</td>
                  <td>{client.subscriptionName}</td>
                  <td>{client.projectName}</td>
                  <td>{client.quotationName}</td>

                  <td>{client.startDate}</td>
                  <td>{client.endDate}</td>
                  <td>{client.amount}</td>
                
                  
                

                  
                </tr>
             );
            })
          ) : (
         <></>
          )}
          </table>
          { clientQationList.length > 0 ?   "": <NoData />}
        </div>
     
          
          </div>
        </Modal.Body>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>
  
      </Modal>












    </div>
  );
}

export default ClientList;
