import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import searchicon from "../../assets/images/search_icon.svg";
import editicon from "../../assets/images/bx_edit copy.svg";
import eye_icon from "../../assets/images/eye_icon.svg";
import { Button, Form, Modal } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import deleteicon from "../../assets/images/delete.svg";

import {
  PostCallWithErrorResponse,
  postWithAuthCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
  simpleGetCallWithToken,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { Link } from "react-router-dom";
import { ToastMsg } from "../TostMsg";
import Loader from "../Loader";

const Ledger = () => {
  const { sidebar } = useContext(AppContext);
  const [loader, setLoader] = useState(null);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showCreate, setShowCreate] = useState(false);
  const handleCloseCreate = () => setShowCreate(false);
  const handleShowCreate = () => setShowCreate(true);

  const [showEdit, setShowEdit] = useState(false);
  const handleCloseEdit = () => setShowEdit(false);

  const [allLedgers, setAllLedgers] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const handleSearchValue = (e) => {
    setSearchVal(e.target.value);
  };
  const [searchPriVal, setSearchPriVal] = useState("");
  const handleSearchPriValue = (e) => {
    setSearchPriVal(e.target.value);
  };
  const getMasterLedger = () => {
    simpleGetCallWithToken(ApiConfig.GET_LEDGER_LIST)
      .then((resp) => {
        console.log(resp.Data);
        if (resp.Success) {
          setAllLedgers(resp.Data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [selectEditID, setSelectEditID] = useState(null);
  const [editPrimVal, setEditPrimVal] = useState("");
  const handleEditPrimaryLedger = (primaryID) => {
    handleShow();
    console.log(selectEditID);

    const selectedLedger = getPrimLedgerList.find(
      (ledger) => ledger.Id === primaryID
    );
    if (selectedLedger) {
      setEditPrimVal(selectedLedger?.Name);
      setSelectEditID(primaryID);
    }
  };

  const [getPrimLedgerList, setGetPriLedgerList] = useState([]);
  const getPrimaryLedger = () => {
    setLoader(true);
    simpleGetCallWithToken(ApiConfig.GET_PRIMARY_LEDGER_LIST)
      .then((resp) => {
        setLoader(false);
        if (resp.Success) {
          console.log(resp.Data);
          setGetPriLedgerList(resp.Data);
          console.log(getPrimLedgerList);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [allLedgerGrp, setAllLedgerGrp] = useState([]);
  const getLedgerGrp = () => {
    simpleGetCallWithToken(ApiConfig.GET_LEDGER_GROUP_LIST)
      .then((resp) => {
        if (resp.Success) {
          setAllLedgerGrp(resp.Data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getMasterLedger();
    getPrimaryLedger();
    getLedgerGrp();
  }, []);
  const [activeTab, setActiveTab] = useState("Primary Ledger Group");
  const handleTabClick = (tabName) => {
    setActiveTab((prev) => {
      return tabName;
    });
  };

  const [primaryVal, setPrimaryVal] = useState("");
  const handlePrimaryVal = (e) => {
    setPrimaryVal(e.target.value);
  };
  const [textErr, setTextErr] = useState(false);
  const handleSubmitPrimary = () => {
    if (primaryVal.trim().length > 0) {
      PostCallWithErrorResponse(
        ApiConfig.CREATE_PRIMARY_LEDGER,
        JSON.stringify({
          name: primaryVal,
        })
      )
        .then((resp) => {
          if (resp?.json?.Success) {
            ToastMsg("success", resp?.json?.Message);
            getPrimaryLedger();
            return resp?.json?.Data;
          } else {
            ToastMsg("error", resp?.json?.Message || "Something went wrong");
          }
        })
        .catch((res) => console.log(res));
      handleCloseCreate();
      setPrimaryVal("");
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight);
      }, 2000);
    } else if (primaryVal.trim().length === 0) {
      setTextErr(true);
    }
  };

  const handleDeletePrimaryLedger = (priID) => {
    simpleGetCallWithToken(`${ApiConfig.DELETE_PRIMARY_LEDGER}?id=${priID}`)
      .then((resp) => {
        if (resp?.Success) {
          ToastMsg("success", "primary ledger group deleted successfully");
          getPrimaryLedger();
        } else {
          ToastMsg("error", "Something went wrong");
        }
      })
      .catch((err) => console.log(err));
  };

  const [errText, setErrText] = useState(false);
  const handleEditPri = () => {
    if (editPrimVal.trim().length > 0) {
      PostCallWithErrorResponse(
        ApiConfig.EDIT_PRIMARY_GROUP,
        JSON.stringify({
          Id: selectEditID,
          Name: editPrimVal,
        })
      )
        .then((resp) => {
          if (resp?.json?.Success) {
            ToastMsg("success", "edited successfully");
            getPrimaryLedger();
            handleClose();
          } else {
            ToastMsg("error", "something went wrong");
          }
        })
        .catch((err) => console.log(err));
    } else if (editPrimVal.trim().length === 0) {
      setErrText(true);
    }
  };

  const [searchGrpLedger, setSearchGrpLedger] = useState("");

  const [ledgerGrpInputs, setLedgerGrpInputs] = useState({
    ledgerGrpName: "",
    nature: "",
    primGrpType: "",
  });
  const handleGrpInputs = (e) => {
    const { name, value } = e.target;
    setLedgerGrpInputs({ ...ledgerGrpInputs, [name]: value });
  };

  const [err, setErr] = useState({
    grpname: "",
    nature: "",
    priGrpType: "",
  });

  const handleSubmitGroupLedger = () => {
    if (
      ledgerGrpInputs.ledgerGrpName.length > 0 &&
      ledgerGrpInputs.nature &&
      ledgerGrpInputs.primGrpType
    ) {
      PostCallWithErrorResponse(
        ApiConfig.CREATE_LEDGER_GROUP,
        JSON.stringify({
          Id: 0,
          GroupName: ledgerGrpInputs.ledgerGrpName,
          Nature: ledgerGrpInputs.nature,
          GroupType: ledgerGrpInputs.primGrpType,
        })
      )
        .then((resp) => {
          if (resp?.json?.Success) {
            ToastMsg("success", "ledger group created successfully");
            handleCloseCreate();
            getLedgerGrp();
            setTimeout(() => {
              window.scrollTo(0, document.body.scrollHeight);
            }, 2000);
            setLedgerGrpInputs({
              ledgerGrpName: "",
              nature: "",
              primGrpType: "",
            });
            setErr({
              grpname: "",
              nature: "",
              priGrpType: "",
            });
          } else {
            ToastMsg("error", "something went wrong");
          }
        })
        .catch((err) => console.log(err));
    } else {
      setErr((prev) => ({
        ...prev,
        grpname: "please enter the Ledger Group Name",
        nature: "please select the Nature",
        priGrpType: "please select Primary Group Type",
      }));
    }
  };

  const handleDeleteLedgerGrp = (GrpID) => {
    simpleGetCallWithToken(`${ApiConfig.DELETE_LEDGER_GROUP_ID}?id=${GrpID}`)
      .then((resp) => {
        if (resp?.Success) {
          ToastMsg("success", "ledger group deleted successfully");
          getLedgerGrp();
        } else {
          ToastMsg("error", "Something went wrong");
        }
      })
      .catch((err) => console.log(err));
  };

  const [editLedGrpId, setLedGrpId] = useState(null);
  const [editLedGrpinputs, setEditLedGrpinput] = useState({
    editGroupName: "",
    editNature: "",
    editGroupType: "",
  });
  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditLedGrpinput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleEditLedgerGrp = (ledGrpID) => {
    handleShow();
    const selectedLEdGrp = allLedgerGrp.find(
      (ledger) => ledger.Id === ledGrpID
    );
    if (selectedLEdGrp) {
      setLedGrpId(ledGrpID);
      setEditLedGrpinput({
        editGroupName: selectedLEdGrp.GroupName,
        editNature: selectedLEdGrp.Nature,
        editGroupType: selectedLEdGrp.GroupType,
      });
    }
  };

  const handleSaveLedgerGrp = () => {
    PostCallWithErrorResponse(
      ApiConfig.EDIT_LEDGER_GROUP,
      JSON.stringify({
        Id: editLedGrpId,
        GroupName:
          editLedGrpinputs.editGroupName || ledgerGrpInputs.ledgerGrpName,
        Nature: editLedGrpinputs.editNature || ledgerGrpInputs.nature,
        GroupType:
          editLedGrpinputs.editGroupType || ledgerGrpInputs.primGrpType,
      })
    )
      .then((resp) => {
        if (resp?.json?.Success) {
          ToastMsg("success", resp?.json?.Message);
          handleClose();
          getLedgerGrp();
          setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
          }, 2000);
        } else {
          ToastMsg("error", resp?.json?.Message);
        }
      })
      .catch((err) => console.log(err));
  };
  const [isBank, setIsBank] = useState(true);
  const [ledgerMasterInputs, setLedgerMasterInputs] = useState({
    name: "",
    ledgerGrpId: 0,
    bankName: "",
    ifscNo: "",
    accountNo: "",
    isBankDetails: isBank,
  });
  const handleLedMasChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setIsBank(checked);
      setLedgerMasterInputs((prev) => ({
        ...prev,
        isBankDetails: checked,
      }));
    } else if (name === "ledgerGrpId") {
      setLedgerMasterInputs((prev) => ({
        ...prev,
        [name]: Number(value),
      }));
    } else {
      setLedgerMasterInputs((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const [errGrp, setErrGrp] = useState({
    ledName: "",
    ledGrp: "",
    isBank: ledgerMasterInputs.isBankDetails,
    bankName: "",
    ifscNum: "",
    accNo: "",
  });
  const handleSubmitLedgerMaster = () => {
    if (ledgerMasterInputs.name.length > 0 && ledgerMasterInputs.ledgerGrpId) {
      PostCallWithErrorResponse(
        ApiConfig.CREATE_UPDATE_LEDGER_MASTER,
        JSON.stringify({
          // "EmployeeId": 0,
          Id: 0,
          Name: ledgerMasterInputs.name,
          LegderGroupId: ledgerMasterInputs.ledgerGrpId,
          IsOutSourced: true,
          IsBankdetails: ledgerMasterInputs.isBankDetails,
          BankName: ledgerMasterInputs.bankName,
          IFSC: ledgerMasterInputs.ifscNo,
          AccountNo: ledgerMasterInputs.accountNo,
        })
      )
        .then((resp) => {
          if (resp?.json?.Success) {
            ToastMsg("success", "ledger master created successfully");
            getMasterLedger();
            handleCloseCreate();
            setTimeout(() => {
              window.scrollTo(0, document.body.scrollHeight);
            }, 2000);
            setErrGrp({
              ledName: "",
              ledGrp: "",
              bankName: "",
              ifscNum: "",
              accNo: "",
            });
          } else if (!resp.json?.Success) {
            ToastMsg("error", resp?.json?.Message);
          } else {
            ToastMsg("error", "something went wrong");
          }
        })
        .catch((err) => console.log(err));
    } else {
      setErrGrp((prev) => ({
        ...prev,
        ledName: "please enter the Ledger Name",
        ledGrp: "please select the Ledger Group",
        bankName: "please enter the Bank Name",
        ifscNum: "please enter the IFSC Number",
        accNo: "please enter the Account Number",
      }));
    }
  };
  const [editIsBank, setIsEditBank] = useState(true);
  const [editLedgerMasterInputs, setEditLedgerMasterInputs] = useState({
    editName: "",
    editLedgerGrpId: "",
    editBankName: "",
    editIfscNo: "",
    editAccountNo: "",
    editIsBankDet: editIsBank,
  });

  const [selectEditLedgerMaster, setSelectEditLedgerMaster] = useState(null);
  const handleEditGroupledger = (editID) => {
    handleShow();
    const selected = allLedgers.find((x) => x.Id === editID);
    console.log(selected);

    simpleGetCallWithToken(
      `${ApiConfig.GET_EDIT_MASTER_BY_ID}?id=${editID}`
    ).then((resp) => {
      if (resp?.Success) {
        console.log(resp?.Data);
        setSelectEditLedgerMaster(resp?.Data);

        setEditLedgerMasterInputs({
          editName: resp?.Data?.Name,
          editLedgerGrpId: resp?.Data?.LegderGroupId,
          editBankName: resp?.Data?.BankName,
          editIfscNo: resp?.Data?.IFSC,
          editAccountNo: resp?.Data?.AccountNo,
          editIsBankDet: resp?.Data?.IsBankdetails,
        });
      } else {
        console.log("err");
      }
    });
  };

  const handleEditLedgerMchange = (e) => {
    const { name, value, checked, type } = e.target;
    if (type === "checkbox") {
      setIsEditBank(checked);
      setEditLedgerMasterInputs((prev) => ({
        ...prev,
        editIsBankDet: checked,
      }));
    } else if (name === "editLedgerGrpId") {
      setEditLedgerMasterInputs((prev) => ({
        ...prev,
        [name]: Number(value),
      }));
    } else {
      setEditLedgerMasterInputs((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleSaveLedgerMaster = () => {
    console.log(selectEditLedgerMaster.Id);

    PostCallWithErrorResponse(
      ApiConfig.CREATE_UPDATE_LEDGER_MASTER,
      JSON.stringify({
        // "EmployeeId": 0,
        Id: selectEditLedgerMaster?.Id,
        LegderGroupId: editLedgerMasterInputs.editLedgerGrpId,
        Name: editLedgerMasterInputs.editName,
        IsOutSourced: true,
        IsBankdetails: editLedgerMasterInputs.editIsBankDet,
        BankName: editLedgerMasterInputs.editBankName,
        IFSC: editLedgerMasterInputs.editIfscNo,
        AccountNo: editLedgerMasterInputs.editAccountNo,
      })
    )
      .then((resp) => {
        console.log(resp);
        if (resp?.json?.Success) {
          ToastMsg("success", "updated successfully");
          getMasterLedger();
          handleClose();
          setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
          }, 2000);
        } else {
          ToastMsg("error", resp?.json?.Message);
        }
      })
      .catch((err) => console.log(err));
  };

  const [showTransaction, setShowTransaction] = useState(null);
  const handleShowTransaction = () => {
    setShowTransaction(true);
  };
  const handleCloseTransaction = () => {
    setShowTransaction(false);
  };

  const [createTransaction, setCreateTransaction] = useState({
    ledgerName: null,
    amount: null,
    effecttype: null,
  });
  const handleCreateTransaction = (e) => {
    const { value, name } = e.target;
    setCreateTransaction((prev) => ({
      ...prev,
      [name]: Number(value),
    }));
  };
  const createTheTransaction = () => {
    console.log(createTransaction);
    PostCallWithErrorResponse(
      ApiConfig.CREATE_TRANSACTION,
      JSON.stringify({
        LedgerId: Number(createTransaction.ledgerName),
        Amount: createTransaction.amount,
        EffectType: createTransaction.effecttype,
      })
    )
      .then((res) => {
        console.log(res);

        if (res?.json?.Success) {
          ToastMsg("success", res?.json?.Message);
          handleCloseTransaction();
          getMasterLedger();
          setCreateTransaction({
            ledgerName: createTransaction.ledgerName,
            amount: null,
            effecttype: null,
          });
        } else {
          ToastMsg("error", res?.json?.Message);
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div className="sub-header-role d-flex justify-content-between">
        <h6 className="mt-2 role-text">Manage Ledgers</h6>
        <div>
          <button
            className="create-btn-sub-header px-1"
            style={{ marginRight: "0" }}
            onClick={handleShowTransaction}
          >
            + Transaction
          </button>
          <button
            className="ms-3 create-btn-sub-header"
            style={{ marginRight: "0" }}
            onClick={handleShowCreate}
          >
            + Create
          </button>
        </div>
      </div>
      <div className="d-flex justify-content-start mt-3">
        <Tabs className="w-100">
          <TabList>
            <Tab>
              <button
                className="my-anc-btn-sub-header "
                onClick={() => handleTabClick("Primary Ledger Group")}
              >
                Primary Ledger Group
              </button>
            </Tab>
            <Tab>
              <button
                className="my-anc-btn-sub-header "
                onClick={() => handleTabClick("Ledger Group")}
              >
                Ledger Group
              </button>
            </Tab>
            <Tab>
              <button
                className="my-anc-btn-sub-header "
                onClick={() => handleTabClick("Ledger Master")}
              >
                Ledger Master
              </button>
            </Tab>
          </TabList>

          <TabPanel>
            <div className="d-flex justify-content-between align-items-center">
              <div className="search-input-wrapper my-auto mt-3">
                <input
                  type="text"
                  value={searchPriVal}
                  onChange={handleSearchPriValue}
                  placeholder="Search"
                />
                <img src={searchicon} alt="" />
              </div>
            </div>

            {loader ? (
              <Loader />
            ) : (
              <div className="table-wrapper">
                <table>
                  <tr>
                    <th>Sr. no</th>
                    <th>Primary Ledger Name</th>
                    <th>Action</th>
                  </tr>
                  {getPrimLedgerList
                    .filter((res) => {
                      return searchPriVal
                        ? res?.Name.toLowerCase().includes(
                            searchPriVal.toLowerCase().trim()
                          )
                        : res;
                    })
                    .map((item, index) => {
                      return (
                        <tr className="table-row-custom">
                          <td>{index + 1}</td>
                          <td>{item.Name}</td>
                          <td>
                            <img
                              src={editicon}
                              onClick={() => handleEditPrimaryLedger(item.Id)}
                              className="me-1"
                            />
                            <img
                              src={deleteicon}
                              onClick={() => handleDeletePrimaryLedger(item.Id)}
                            />
                          </td>
                        </tr>
                      );
                    })}
                </table>
              </div>
            )}
          </TabPanel>
          <TabPanel>
            <div className="d-flex justify-content-between align-items-center">
              <div className="search-input-wrapper my-auto mt-3">
                <input
                  type="text"
                  value={searchGrpLedger}
                  onChange={(e) => setSearchGrpLedger(e.target.value)}
                  placeholder="Search"
                />
                <img src={searchicon} alt="" />
              </div>
            </div>
            <div className="table-wrapper">
              <table>
                <tr>
                  <th>Sr. no</th>
                  <th>Ledger Group Name</th>
                  <th>Nature</th>
                  <th>Primary Group</th>
                  <th>Action</th>
                </tr>
                {allLedgerGrp
                  .filter((res) => {
                    return searchGrpLedger
                      ? res?.Name?.toLowerCase().includes(
                          searchGrpLedger.toLowerCase().trim()
                        )
                      : res;
                  })
                  .map((item, index) => {
                    return (
                      <tr className="table-row-custom">
                        <td>{index + 1}</td>
                        <td>{item.GroupName}</td>
                        <td>{item.Nature}</td>
                        <td>{item.GroupType}</td>
                        <td>
                          <img
                            src={editicon}
                            className="me-1"
                            onClick={() => handleEditLedgerGrp(item.Id)}
                          />
                          <img
                            src={deleteicon}
                            onClick={() => handleDeleteLedgerGrp(item.Id)}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </table>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="d-flex justify-content-between align-items-center">
              <div className="search-input-wrapper my-auto mt-3">
                <input
                  type="text"
                  value={searchVal}
                  onChange={handleSearchValue}
                  placeholder="Search"
                />
                <img src={searchicon} alt="" />
              </div>
            </div>

            <div className="table-wrapper">
              <table>
                <tr>
                  <th>Sr. no</th>
                  <th>Ledger Name</th>
                  <th>Ledger Group Name</th>
                  <th>Primary Ledger Name</th>
                  <th>Action</th>
                </tr>

                {allLedgers
                  .filter((item) => {
                    return searchVal
                      ? item?.Name?.toLowerCase().includes(
                          searchVal.toLowerCase().trim()
                        )
                      : item;
                  })
                  .map((led, index) => (
                    <tr className="table-row-custom">
                      <td>{index + 1}</td>
                      <td>{led.Name}</td>
                      <td>{led.LedgerGroupName}</td>
                      <td>{led.PrimaryGroup}</td>
                      <td>
                        <img
                          src={editicon}
                          onClick={() => handleEditGroupledger(led.Id)}
                          className="me-1"
                        />
                        <Link to={`/viewtransactions/${led.Id}`}>
                          <img
                            src={eye_icon}
                            alt="view transactions"
                            // onClick={() => handleShowView(led.Id)}
                          />
                        </Link>
                      </td>
                    </tr>
                  ))}
              </table>
            </div>
          </TabPanel>
        </Tabs>
      </div>

      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {activeTab === "Primary Ledger Group"
              ? `Edit Primary Ledger Group`
              : activeTab === "Ledger Group"
              ? "Edit Ledger Group"
              : activeTab === "Ledger Master"
              ? "Edit Ledger Master"
              : null}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {activeTab === "Primary Ledger Group" ? (
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Select Ledger</Form.Label>
              <Form.Control
                type="text"
                value={editPrimVal}
                onChange={(e) => setEditPrimVal(e.target.value)}
                placeholder="Select Ledger"
              />
              {editPrimVal.trim().length === 0 && (
                <Form.Text className="text-danger">
                  Please select the ledger
                </Form.Text>
              )}
            </Form.Group>
          ) : activeTab === "Ledger Group" ? (
            <>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Ledger Group Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Ledger Group Name"
                  name="editGroupName"
                  id="editGroupName"
                  value={editLedGrpinputs.editGroupName}
                  onChange={handleEditChange}
                />
                {/* {textErr && primaryVal.trim().length === 0 && (
                  <Form.Text className="text-danger">
                    Please enter the Ledger Group Name
                  </Form.Text>
                )} */}
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label htmlFor="disabledSelect">Nature</Form.Label>
                <Form.Select
                  id="editNature"
                  name="editNature"
                  value={editLedGrpinputs.editNature}
                  onChange={handleEditChange}
                >
                  <option disabled selected value="">
                    select Nature
                  </option>
                  <option value="Debit">Debit</option>
                  <option value="Credit">Credit</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Primary Group Type</Form.Label>
                <Form.Select
                  id="editGroupType"
                  name="editGroupType"
                  value={editLedGrpinputs.editGroupType}
                  onChange={handleEditChange}
                >
                  <option selected disabled value="">
                    select Primary Group Type
                  </option>
                  {getPrimLedgerList.map((item, index) => {
                    return (
                      <option value={item.Name.toUpperCase()} key={item.Id}>
                        {item.Name}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </>
          ) : activeTab === "Ledger Master" ? (
            <>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Ledger Name</Form.Label>
                <Form.Control
                  type="text"
                  name="editName"
                  id="editName"
                  placeholder="Enter Ledger Name"
                  value={editLedgerMasterInputs.editName}
                  onChange={handleEditLedgerMchange}
                ></Form.Control>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Ledger Group</Form.Label>
                <Form.Select
                  placeholder="Enter Ledger Name"
                  name="editLedgerGrpId"
                  id="editLedgerGrpId"
                  value={editLedgerMasterInputs.editLedgerGrpId || ""}
                  onChange={handleEditLedgerMchange}
                >
                  <option disabled value="">
                    Select Ledger Group
                  </option>
                  {allLedgerGrp.map((item) => (
                    <option value={item.Id} key={item.Id}>
                      {item.GroupName}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Check
                  type="checkbox"
                  id="editIsBankDet"
                  label="Is Bank Account?"
                  name="editIsBankDet"
                  checked={editLedgerMasterInputs.editIsBankDet}
                  onChange={handleEditLedgerMchange}
                />
              </Form.Group>

              {editLedgerMasterInputs.editIsBankDet && (
                <>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Bank Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="editBankName"
                      id="editBankName"
                      placeholder="Select Bank Name"
                      value={editLedgerMasterInputs.editBankName}
                      onChange={handleEditLedgerMchange}
                    />
                    <Form.Text className="text-muted">
                      Please select the Bank Name
                    </Form.Text>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>IFSC</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter IFSC"
                      name="editIfscNo"
                      id="editIfscNo"
                      value={editLedgerMasterInputs.editIfscNo}
                      onChange={handleEditLedgerMchange}
                    />
                    <Form.Text className="text-muted">
                      Please enter the IFSC
                    </Form.Text>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Account No.</Form.Label>
                    <Form.Control
                      type="number"
                      name="editAccountNo"
                      id="editAccountNo"
                      placeholder="Enter Account No"
                      value={editLedgerMasterInputs.editAccountNo}
                      onChange={handleEditLedgerMchange}
                    />
                    <Form.Text className="text-muted">
                      Please enter the Account No
                    </Form.Text>
                  </Form.Group>
                </>
              )}
            </>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleClose} className="modal-cancel-btn">
            Close
          </button>
          <button
            onClick={
              activeTab === "Primary Ledger Group"
                ? handleEditPri
                : activeTab === "Ledger Group"
                ? handleSaveLedgerGrp
                : activeTab === "Ledger Master"
                ? handleSaveLedgerMaster
                : null
            }
            className="AddQuestion_submit_btn"
          >
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>

      <Modal centered show={showCreate} onHide={handleCloseCreate}>
        <Modal.Header closeButton>
          <Modal.Title>
            {activeTab === "Primary Ledger Group"
              ? `Primary Ledger Group`
              : activeTab === "Ledger Group"
              ? "Ledger Group"
              : "Ledger Master"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {activeTab === "Primary Ledger Group" ? (
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Primary Ledger Name</Form.Label>
              <Form.Control
                value={primaryVal}
                onChange={handlePrimaryVal}
                type="text"
                placeholder="Enter Ledger Name"
              />
              {textErr && primaryVal.trim().length === 0 && (
                <Form.Text className="text-danger">
                  Please enter the Ledger Name
                </Form.Text>
              )}
            </Form.Group>
          ) : activeTab === "Ledger Group" ? (
            <>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Ledger Group Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Ledger Group Name"
                  name="ledgerGrpName"
                  id="ledgerGrpName"
                  value={ledgerGrpInputs.ledgerGrpName}
                  onChange={handleGrpInputs}
                />
                {err.grpname &&
                  ledgerGrpInputs.ledgerGrpName.trim().length === 0 && (
                    <Form.Text className="text-danger">{err.grpname}</Form.Text>
                  )}
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label htmlFor="disabledSelect">Nature</Form.Label>
                <Form.Select
                  id="nature"
                  name="nature"
                  value={ledgerGrpInputs.nature}
                  onChange={handleGrpInputs}
                >
                  <option disabled selected value="">
                    select Nature
                  </option>
                  <option value="Debit">Debit</option>
                  <option value="Credit">Credit</option>
                </Form.Select>
                {err.nature && !ledgerGrpInputs.nature && (
                  <Form.Text className="text-danger">{err.nature}</Form.Text>
                )}
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Primary Group Type</Form.Label>
                <Form.Select
                  id="primGrpType"
                  name="primGrpType"
                  value={ledgerGrpInputs.primGrpType}
                  onChange={handleGrpInputs}
                >
                  <option selected disabled value="">
                    select Primary Group Type
                  </option>
                  {getPrimLedgerList.map((item, index) => {
                    return (
                      <option value={item.Name.toUpperCase()} key={item.Id}>
                        {item.Name}
                      </option>
                    );
                  })}
                </Form.Select>
                {err.priGrpType && !ledgerGrpInputs.primGrpType && (
                  <Form.Text className="text-danger">
                    {err.priGrpType}
                  </Form.Text>
                )}
              </Form.Group>
            </>
          ) : (
            <>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Ledger Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Enter Ledger Name"
                  value={ledgerMasterInputs.name}
                  onChange={handleLedMasChange}
                ></Form.Control>
                {errGrp.ledName &&
                  ledgerMasterInputs.name.trim().length === 0 && (
                    <Form.Text className="text-danger">
                      {errGrp.ledName}
                    </Form.Text>
                  )}
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Ledger Group</Form.Label>
                <Form.Select
                  name="ledgerGrpId"
                  id="ledgerGrpId"
                  value={ledgerMasterInputs.ledgerGrpId || ""}
                  onChange={handleLedMasChange}
                >
                  <option disabled value="">
                    Select Ledger Group
                  </option>
                  {allLedgerGrp.map((item) => (
                    <option value={item.Id} key={item.Id}>
                      {item.GroupName}
                    </option>
                  ))}
                </Form.Select>
                {errGrp.ledGrp && !ledgerMasterInputs.ledgerGrpId && (
                  <Form.Text className="text-danger">{errGrp.ledGrp}</Form.Text>
                )}
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Check
                  type="checkbox"
                  id="isBankDetails"
                  label="Is Bank Account?"
                  name="isBankDetails"
                  checked={ledgerMasterInputs.isBankDetails}
                  onChange={handleLedMasChange}
                />
              </Form.Group>

              {ledgerMasterInputs.isBankDetails && (
                <>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Bank Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Select Bank Name"
                      name="bankName"
                      id="bankName"
                      value={ledgerMasterInputs.bankName}
                      onChange={handleLedMasChange}
                    />
                    {errGrp.isBank &&
                      ledgerMasterInputs.bankName.length === 0 && (
                        <Form.Text className="text-danger">
                          {errGrp.bankName}
                        </Form.Text>
                      )}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>IFSC</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter IFSC"
                      name="ifscNo"
                      id="ifscNo"
                      value={ledgerGrpInputs.ifscNo}
                      onChange={handleLedMasChange}
                    />
                    {errGrp.isBank &&
                      ledgerMasterInputs.ifscNo.length === 0 && (
                        <Form.Text className="text-danger">
                          {errGrp.ifscNum}
                        </Form.Text>
                      )}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Account No.</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter Account No"
                      name="accountNo"
                      id="accountNo"
                      value={ledgerMasterInputs.accountNo}
                      onChange={handleLedMasChange}
                    />
                    {errGrp.isBank &&
                      ledgerMasterInputs.accountNo.length === 0 && (
                        <Form.Text className="text-danger">
                          {errGrp.accNo}
                        </Form.Text>
                      )}
                  </Form.Group>{" "}
                </>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleCloseCreate} className="modal-cancel-btn">
            Close
          </button>
          <button
            onClick={
              activeTab === "Primary Ledger Group"
                ? handleSubmitPrimary
                : activeTab === "Ledger Group"
                ? handleSubmitGroupLedger
                : activeTab === "Ledger Master"
                ? handleSubmitLedgerMaster
                : null
            }
            className="AddQuestion_submit_btn"
          >
            Create
          </button>
        </Modal.Footer>
      </Modal>

      <Modal centered show={showEdit} onHide={handleCloseEdit}>
        <Modal.Header closeButton>
          <Modal.Title>Primary Ledger Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Primary Ledger Name</Form.Label>
            <Form.Control type="text" placeholder="Enter Ledger Name" />
            <Form.Text className="text-muted">
              Please enter the Ledger Name
            </Form.Text>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleCloseEdit} className="modal-cancel-btn">
            Close
          </button>
          <button onClick={handleCloseEdit} className="AddQuestion_submit_btn">
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>
      <Modal centered show={showTransaction} onHide={handleCloseTransaction}>
        <Modal.Header closeButton>
          <Modal.Title>Create Transaction</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Select Ledger</Form.Label>
            <Form.Select
              id="ledgerName"
              name="ledgerName"
              value={createTransaction.ledgerName}
              onChange={handleCreateTransaction}
            >
              <option selected disabled value="">
                Select Ledger
              </option>
              {allLedgers.map((item) => {
                return <option value={item.Id}>{item.Name}</option>;
              })}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Amount</Form.Label>
            <Form.Control
              type="number"
              name="amount"
              id="amount"
              placeholder="Enter Amount"
              value={createTransaction.amount}
              onChange={handleCreateTransaction}
            ></Form.Control>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Effect Type</Form.Label>
            <Form.Select
              id="effecttype"
              name="effecttype"
              value={createTransaction.effecttype}
              onChange={handleCreateTransaction}
            >
              <option selected disabled value="">
                Select Effect Type
              </option>
              <option value="1">Debit</option>
              <option value="2">Credit</option>
            </Form.Select>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleCloseTransaction} className="modal-cancel-btn">
            Close
          </button>
          <button
            className="AddQuestion_submit_btn"
            onClick={createTheTransaction}
          >
            Create
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default Ledger;
