import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import {
  PostCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
  simpleGetCallWithToken,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../Loader";
import NoData from "../../sharedComponent/NoData";
import moment from "moment";
import searchicon from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import Pagination from "../../sharedComponent/Pagination";
import { getDateddmmyyyy } from "../../common";
import BookIcon from "../../assets/images/ic_excel.svg";
import FileSaver from "file-saver";
import ExcelLoader from "../../sharedComponent/ExcelLoader";

const EmployeeTimesheetDetails = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);
  const [employeeName, setEmployeeName] = useState([]);
  const [YearList, setYearList] = useState([]);

  const [totalHours, setTotalHours] = useState(0);

  const [excelLoading, setExcelLoading] = useState(false);

  // Month array[];
  const months = [
    { id: 1, value: "January" },
    { id: 2, value: "February" },
    { id: 3, value: "March" },
    { id: 4, value: "April" },
    { id: 5, value: "May" },
    { id: 6, value: "June" },
    { id: 7, value: "July" },
    { id: 8, value: "August" },
    { id: 9, value: "September" },
    { id: 10, value: "October" },
    { id: 11, value: "November" },
    { id: 12, value: "December" },
  ];
  let totalWorkedHours = 0;
  const [selectedData, setSelectedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addEmpData, setAddEmpData] = useState({
    month: 0,
    year: 0,
    EmpId: 0,
  });
  const [empDataErr, setEmpDataErr] = useState({
    monthErr: "",
    yearErr: "",
    EmpIdErr: "",
  });

  const changeData = (e) => {
    const { name, value } = e.target;
    setAddEmpData({
      ...addEmpData,
      [name]: value,
    });
  };

  const fetchNameList = () => {
    simpleGetCallWithToken(ApiConfig.GET_EMPLOYEE_NAMES_LIST)
      .then((res) => {
        if (res.Success === true) {
          setEmployeeName(res.Data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const fetchYearList = () => {
    simpleGetCallWithToken(ApiConfig.TEAMSHEET_YEAR)
      .then((res) => {
        if (res.Success === true) {
          setYearList(res.Data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [totalHour, setTotalHour] = useState(0);
  const searchData = () => {
    if (addEmpData.EmpId === 0) {
      setEmpDataErr({
        ...empDataErr,
        EmpIdErr: "Please Select Employee Name",
      });
    } else if (addEmpData.year === 0) {
      setEmpDataErr({
        ...empDataErr,
        yearErr: "Please Select year",
      });
    } else if (addEmpData.month === 0) {
      setEmpDataErr({
        ...empDataErr,
        monthErr: "Please Select Month",
      });
    } else {
      setLoading(true);
      let payload = {
        month: JSON.parse(addEmpData?.month),
        year: JSON.parse(addEmpData?.year),
        EmpId: JSON.parse(addEmpData?.EmpId),
      };
      PostCallWithErrorResponse(
        ApiConfig.POST_EMPLOYEE_CREDENTIALS,
        JSON.stringify(payload)
      )
        .then((res) => {
          if (res.json.Success === true) {
            setLoading(false);
            setSelectedData(res.json.Data);
            setTotalHour(res.json?.TotalHour / 60);
            console.log("API Respoinse -------", res.json.TotalHours);
            setTotalHour(res.json.TotalHours);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    fetchNameList();
    fetchYearList()
  }, []);

  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(25);

  const startIndex = currentPage * usersPerPage;
  const endIndex = startIndex + parseInt(usersPerPage);

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected);
  };

  const searchedDataPageCounts =
    selectedData &&
    selectedData.length &&
    selectedData.filter((item) =>
      item.ProjectName?.toLowerCase().includes(searchValue)
    );

  const displayData =
    selectedData &&
    selectedData.slice(startIndex, endIndex).map((data, index) => {
      return (
        <tr className="table-row-custom" key={index}>
          <td>{index + 1 + startIndex}</td>
          <td>
            {data.ProjectName ? (
              data.ProjectName
            ) : (
              <span className="text-danger fw-bod">No Data</span>
            )}
          </td>
          <td className="adjustWidth">
            {data.TaskName ? (
              data.TaskName
            ) : (
              <span className="text-danger fw-bod">No Data</span>
            )}
          </td>
          <td className="adjustWidth">
            {data.Activity ? (
              data.Activity
            ) : (
              <span className="text-danger fw-bod">No Data</span>
            )}
          </td>
          <td>
            {data.WorkedDate ? (
              data.WorkedDate &&
              getDateddmmyyyy(data.WorkedDate) &&
              getDateddmmyyyy(data.WorkedDate)
            ) : (
              <span className="text-danger fw-bod">No Data</span>
            )}
          </td>
          <td>
            {(data.WorkedHour / 60).toFixed(2) ? (
              (data.WorkedHour / 60).toFixed(2)
            ) : (
              <span className="text-danger fw-bod">No Data</span>
            )}
          </td>
        </tr>
      );
    });

  const downloadExcelFile = (e, EmpId) => {
    setExcelLoading(true);
    e.preventDefault();
    PostCallWithErrorResponse(
      ApiConfig.EMPTIME_DOWNLOAD_EXCEL,
      JSON.stringify({
        EmpId: Number(addEmpData.EmpId),
        month: Number(addEmpData.month),
        year: new Date().getFullYear(),
      })
    )
      .then((res) => {
        console.log("Download File Response", res);
        if (res.error) {
          console.log("Error response", res);
        } else {
          if (res.json.Success === true) {
            setExcelLoading(false);
            let data = res.json.Data;
            FileSaver.saveAs(
              "data:" + data.ContentType + ";base64," + data.FileContents,
              data.FileDownloadName
            );
          } else {
            console.log("res", res);
            setExcelLoading(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const displaySearchedData =
    selectedData &&
    selectedData
      ?.filter((item) => item.ProjectName?.toLowerCase().includes(searchValue))
      ?.slice(startIndex, endIndex)
      .map((data, index) => {
        return (
          <tr className="table-row-custom" key={index}>
            <td>{index + 1}</td>
            <td>
              {data.ProjectName ? (
                data.ProjectName
              ) : (
                <span className="text-danger fw-bod">No Data</span>
              )}
            </td>
            <td className="adjustWidth">
              {data.TaskName ? (
                data.TaskName
              ) : (
                <span className="text-danger fw-bod">No Data</span>
              )}
            </td>
            <td className="adjustWidth">
              {data.Activity ? (
                data.Activity
              ) : (
                <span className="text-danger fw-bod">No Data</span>
              )}
            </td>
            <td>
              {data.WorkedDate ? (
                moment(data.WorkedDate).format("L")
              ) : (
                <span className="text-danger fw-bod">No Data</span>
              )}
            </td>
            <td>
              {(data.WorkedHour / 60).toFixed(2) ? (
                (data.WorkedHour / 60).toFixed(2)
              ) : (
                <span className="text-danger fw-bod">No Data</span>
              )}
            </td>
          </tr>
        );
      });

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <h6 className="mt-2">Employee Timesheet Details</h6>
          </div>
          <div className="innerRoleSec">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-8 col-md-12">
                <div className="mb-4">
                  <label htmlFor="" className="enter-role-text">
                    Select Employee Name
                  </label>
                  <select
                    onChange={(e) => {
                      changeData(e);
                    }}
                    name="EmpId"
                    value={addEmpData.EmpId}
                    class="form-select tasKCategory1 select-drop-icon"
                    aria-label="Default select example"
                  >
                    <option value="">Select Employee Name</option>
                    {employeeName.map((name, index) => {
                      return (
                        <option value={name.Id} key={index}>
                          {name.FullName}
                        </option>
                      );
                    })}
                  </select>
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    {addEmpData.EmpId <= 0 ? empDataErr.EmpIdErr : ""}
                  </span>
                </div>
              </div>
              <div className="col-lg-8 col-md-12">
                <div className="mb-4">
                  <label htmlFor="" className="enter-role-text">
                    Select Year
                  </label>
                  <select
                    onChange={(e) => {
                      changeData(e);
                    }}
                    name="year"
                    value={addEmpData.year}
                    class="form-select tasKCategory1 select-drop-icon"
                    aria-label="Default select example"
                  >
                    <option value="">Select Year</option>
                 
                    {YearList.map((name, index) => {
                      return (
                        <option value={name.Id} key={index}>
                          {name.year}
                        </option>
                      );
                    })}
                  </select>
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    {addEmpData.year <= 0 ? empDataErr.yearErr : ""}
                  </span>
                </div>
              </div>
              <div className="col-lg-8 col-md-12">
                <div className="mb-4">
                  <label htmlFor="" className="enter-role-text">
                    Select Month
                  </label>
                  <select
                    onChange={(e) => {
                      changeData(e);
                    }}
                    name="month"
                    value={addEmpData.month}
                    class="form-select tasKCategory1 select-drop-icon"
                    aria-label="Default select example"
                  >
                    <option value="">Select Month</option>
                    {months.map((data, index) => {
                      return (
                        <option value={data.id} key={index}>
                          {data.value}
                        </option>
                      );
                    })}
                  </select>
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    {addEmpData.month <= 0 ? empDataErr.monthErr : ""}
                  </span>
                </div>
              </div>
              <div className="col-md-8 text-end">
                <button
                  type="button"
                  className="modal-create-btn"
                  onClick={searchData}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
          {loading ? (
            <>
              <Loader />
            </>
          ) : (
            <>
              {selectedData && selectedData.length > 0 ? (
                <>
                  <div className="role-searchbar-and-btns RspSalary">
                    <div className="role-table-header d-flex justify-content-between">
                      <div className="search-input-wrapper">
                        <input
                          type="text"
                          placeholder="Search"
                          onChange={(e) => {
                            setSearchValue(e.target.value);
                            setCurrentPage(0);
                          }}
                        />
                        {Dark === "lightMode" ? (
                          <img src={searchicon} alt="" />
                        ) : (
                          <img src={ic_search_dark} alt="" />
                        )}
                      </div>

                      <div className="entries-wrapper">
                        <p>Entries per page</p>
                        <select
                          className="form-select select-drop-icon"
                          aria-label="Default select example"
                          onChange={(e) => {
                            setUsersPerPage(e.target.value);
                            setCurrentPage(0);
                          }}
                        >
                          <option value="5">5</option>
                          <option value="10">10</option>
                          <option value="15">15</option>
                          <option value="20">20</option>
                          <option value="25" selected>
                            25
                          </option>
                        </select>
                      </div>

                      <div className="booksIcon mt-3">
                        {excelLoading ? (
                          <ExcelLoader />
                        ) : (
                          <img
                            src={BookIcon}
                            onClick={(e) => downloadExcelFile(e)}
                            className="innerIconsBtn"
                            alt=""
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="table-wrapper" style={{ height: "60vh" }}>
                    <table id="customTable">
                      <thead>
                        <tr className="firstTabelRow">
                          <th>Sr.No</th>
                          <th>Project Name</th>
                          <th>Task</th>
                          <th>Activity</th>
                          <th>Work Date</th>
                          <th>Work hours</th>
                        </tr>
                      </thead>
                      <tbody>
                        {searchValue === "" ? displayData : displaySearchedData}
                        {/*
                        <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="d-flex justify-content-end">
                          <span className="txtBoldTotal">Total Hours =</span>
                        </td>
                        <td>
                          <span className="txtBoldTotal">
                            {totalHours && totalHours}
                          </span>
                        </td>
                      </tr>
                      */}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <>
                  <NoData />
                </>
              )}
            </>
          )}
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {selectedData.length > 0 && (
            <div>
              <span className="txtBoldTotal">Total Hours =</span>{" "}
              <span className="txtBoldTotal">
                {(totalHour / 60).toFixed(2)}
              </span>
            </div>
          )}
          <div style={{ marginTop: "60px" }}>
            {!loading && (
              <Pagination
                data={selectedData}
                pageChangeHandler={pageChangeHandler}
                usersPerPage={usersPerPage}
                currentPage={currentPage}
                searchValue={searchValue}
                searchedDataPageCounts={searchedDataPageCounts}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeTimesheetDetails;
