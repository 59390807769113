import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import {
  PostCallWithErrorResponse,
  simpleGetCallWithToken,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import NoData from "../../sharedComponent/NoData";
import Loader from "../Loader";
import moment from "moment";
import searchicon from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import Pagination from "../../sharedComponent/Pagination";
import { getDateddmmyyyy } from "../../common";
import BookIcon from "../../assets/images/ic_excel.svg";
import FileSaver from "file-saver";
import ExcelLoader from "../../sharedComponent/ExcelLoader";

const ProjectTimesheetDetails = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);
  const [employeeName, setEmployeeName] = useState([]);
  const [projectNames, setProjectNames] = useState([]);
  // Month array[];
  const months = [
    { id: 1, value: "January" },
    { id: 2, value: "February" },
    { id: 3, value: "March" },
    { id: 4, value: "April" },
    { id: 5, value: "May" },
    { id: 6, value: "June" },
    { id: 7, value: "July" },
    { id: 8, value: "August" },
    { id: 9, value: "September" },
    { id: 10, value: "October" },
    { id: 11, value: "November" },
    { id: 12, value: "December" },
  ];
  let totalWorkedHours = 0;
  const [selectedData, setSelectedData] = useState([]);
  const [teamList,setTeamList] = useState([])
  const [loading, setLoading] = useState(false);
  const [addProjData, setAddProjData] = useState({
    month: 0,
    year: 0,
    projId: 0,
    teamId:0
  });
  const [empDataErr, setEmpDataErr] = useState({
    monthErr: "",
    yearErr: "",
    projIdErr: "",
    teamIdErro:""
  });
  const changeData = (e) => {
    const { name, value } = e.target;
    setAddProjData({
      ...addProjData,
      [name]: value,
    });
  };
  const [excelLoading, setExcelLoading] = useState(false);
  const fetchNameList = () => {
    simpleGetCallWithToken(ApiConfig.GET_PROJECTNAMES_LIST)
      .then((res) => {
        if (res.Success === true) {
          setProjectNames(res.Data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchTeamList = () => {
    simpleGetCallWithToken(ApiConfig.GET_TEAM_LIST)
      .then((res) => {
        if (res.Success === true) {
          setTeamList(res.Data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [totalHours, setTotalHours] = useState(0);
  const searchData = () => {
    if (addProjData.projId === 0) {
      setEmpDataErr({
        ...empDataErr,
        projIdErr: "Please select Project Name",
      });
    } else if (addProjData.year === 0) {
      setEmpDataErr({
        ...empDataErr,
        yearErr: "Please select Year",
      });
    } else if (addProjData.month === 0) {
      setEmpDataErr({
        ...empDataErr,
        monthErr: "Please select Month",
      });
    } else {
      setLoading(true);
      let payload = {
        month: JSON.parse(addProjData.month),
        year: JSON.parse(addProjData.year),
        ProjectId: JSON.parse(addProjData.projId),
        TeamId:JSON.parse(addProjData.teamId),
      };
      PostCallWithErrorResponse(
        ApiConfig.POST_TIMESHEET_DETAILS,
        JSON.stringify(payload)
      ).then((res) => {
        console.log("Res >>>>>>", res.Data);
        if (res.json.Success === true) {
          setLoading(false);
          setSelectedData(res.json.Data);
          setTotalHours(res.json?.TotalHours);
        }
      });
    }
  };

  const downloadExcelFile = (e, ProjectId) => {
    setExcelLoading(true);
    e.preventDefault();
    PostCallWithErrorResponse(
      ApiConfig.PROTIME_DOWNLOAD_EXCEL,
      JSON.stringify({
        ProjectId: Number(addProjData.projId),
        month: Number(addProjData.month),
        year: new Date().getFullYear(),
      })
    )
      .then((res) => {
        console.log("Download File Response", res);
        if (res.error) {
          console.log("Error response", res);
        } else {
          if (res.json.Success === true) {
            setExcelLoading(false);
            let data = res.json.Data;
            FileSaver.saveAs(
              "data:" + data.ContentType + ";base64," + data.FileContents,
              data.FileDownloadName
            );
          } else {
            console.log("res", res);
            setExcelLoading(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchNameList();
    fetchTeamList();
    fetchYearList()
  }, []);

  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(25);

  const startIndex = currentPage * usersPerPage;
  const endIndex = startIndex + parseInt(usersPerPage);

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected);
  };

  const searchedDataPageCounts =
    selectedData &&
    selectedData.length &&
    selectedData.filter((item) =>
      item.EmployeeName?.toLowerCase().includes(searchValue)
    );

  const displayData =
    selectedData &&
    selectedData.slice(startIndex, endIndex).map((data, index) => {
      return (
        <tr className="table-row-custom" key={index}>
          <td>{index + 1 + startIndex}</td>
          <td>
            {data.EmployeeName ? (
              data.EmployeeName
            ) : (
              <span className="text-danger fw-bod">-</span>
            )}
          </td>
          <td className="arrangeSpace">
            {data.Activity ? (
              data.Activity
            ) : (
              <span className="text-danger fw-bod">-</span>
            )}
          </td>
          <td>
            {data.WorkedDate ? (
              data.WorkedDate &&
              getDateddmmyyyy(data.WorkedDate) &&
              getDateddmmyyyy(data.WorkedDate)
            ) : (
              <span className="text-danger fw-bod">-</span>
            )}
          </td>
          <td>
            {data.WorkedHour ? (
              data.WorkedHour
            ) : (
              <span className="text-danger fw-bod">-</span>
            )}
          </td>
        </tr>
      );
    });

  const displaySearchedData =
    selectedData &&
    selectedData
      ?.filter((item) => item.EmployeeName?.toLowerCase().includes(searchValue))
      ?.slice(startIndex, endIndex)
      .map((data, index) => {
        return (
          <tr className="table-row-custom" key={index}>
            <td>{index + 1 + startIndex}</td>
            <td>
              {data.EmployeeName ? (
                data.EmployeeName
              ) : (
                <span className="text-danger fw-bod">-</span>
              )}
            </td>
            <td className="arrangeSpace">
              {data.Activity ? (
                data.Activity
              ) : (
                <span className="text-danger fw-bod">-</span>
              )}
            </td>
            <td>
              {data.WorkedDate ? (
                data.WorkedDate &&
                getDateddmmyyyy(data.WorkedDate) &&
                getDateddmmyyyy(data.WorkedDate)
              ) : (
                <span className="text-danger fw-bod">-</span>
              )}
            </td>
            <td>
              {data.WorkedHour ? (
                data.WorkedHour
              ) : (
                <span className="text-danger fw-bod">-</span>
              )}
            </td>
          </tr>
        );
      });

      const [YearList, setYearList] = useState([]);
 

      const fetchYearList = () => {
         simpleGetCallWithToken(ApiConfig.TEAMSHEET_YEAR)
           .then((res) => {
             if (res.Success === true) {
               setYearList(res.Data);
             }
           })
           .catch((err) => {
             console.log(err);
           });
       };
     






  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <h6 className="mt-2">Project Timesheet Details</h6>
          </div>
          <div className="innerRoleSec">
            <div className="row d-flex justify-content-center">
            <div className="col-lg-8 col-md-12">
                <div className="mb-4">
                  <label htmlFor="" className="enter-role-text">
                    Select Team
                  </label>
                  <select
                    onChange={(e) => {
                      changeData(e);
                    }}
                    name="teamId"
                    value={addProjData.teamId}
                    class="form-select tasKCategory1 select-drop-icon"
                    aria-label="Default select example"
                  >
                    <option value="">Select Team</option>
                    {teamList.map((team, index) => {
                      return (
                        <option value={team.Id} key={index}>
                          {team.TeamName}
                        </option>
                      );
                    })}
                  </select>
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    {addProjData.projId <= 0 ? empDataErr.projIdErr : ""}
                  </span>
                </div>
              </div>
              <div className="col-lg-8 col-md-12">
                <div className="mb-4">
                  <label htmlFor="" className="enter-role-text">
                    Select Project Name
                  </label>
                  <select
                    onChange={(e) => {
                      changeData(e);
                    }}
                    name="projId"
                    value={addProjData.projId}
                    class="form-select tasKCategory1 select-drop-icon"
                    aria-label="Default select example"
                  >
                    <option value="">Select Project Name</option>
                    {projectNames.map((name, index) => {
                      return (
                        <option value={name.Id} key={index}>
                          {name.ProjectName}
                        </option>
                      );
                    })}
                  </select>
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    {addProjData.projId <= 0 ? empDataErr.projIdErr : ""}
                  </span>
                </div>
              </div>
              <div className="col-lg-8 col-md-12">
                <div className="mb-4">
                  <label htmlFor="" className="enter-role-text">
                    Select Year
                  </label>
                  <select
                  



                  onChange={(e) => {
                      changeData(e);
                    }}
                    name="year"
                    value={addProjData.year}
                    class="form-select tasKCategory1 select-drop-icon"
                    aria-label="Default select example"
                  >
                    <option value="">Select Year</option>
                 
                    {YearList.map((name, index) => {
                      return (
                        <option value={name.Id} key={index}>
                          {name.year}
                        </option>
                      );
                    })}
                  </select>
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    {addProjData.year <= 0 ? empDataErr.yearErr : ""}
                  </span>
                </div>
              </div>
              <div className="col-lg-8 col-md-12">
                <div className="mb-4">
                  <label htmlFor="" className="enter-role-text">
                    Select Month
                  </label>
                  <select
                    onChange={(e) => {
                      changeData(e);
                    }}
                    name="month"
                    value={addProjData.month}
                    class="form-select tasKCategory1 select-drop-icon"
                    aria-label="Default select example"
                  >
                    <option value="">Select Month</option>
                    {months.map((name, index) => {
                      return (
                        <option value={name.id} key={index}>
                          {name.value}
                        </option>
                      );
                    })}
                  </select>
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    {addProjData.month <= 0 ? empDataErr.monthErr : ""}
                  </span>
                </div>
              </div>
              <div className="col-md-8 text-end">
                <button className="modal-create-btn" onClick={searchData}>
                  Search
                </button>
              </div>
            </div>
          </div>
          {loading ? (
            <>
              <Loader />
            </>
          ) : (
            <>
              {selectedData && selectedData.length > 0 ? (
                <>
                  <div className="role-searchbar-and-btns RspSalary">
                    <div className="role-table-header d-flex justify-content-between">
                      <div className="search-input-wrapper">
                        <input
                          type="text"
                          placeholder="Search"
                          onChange={(e) => {
                            setSearchValue(e.target.value);
                            setCurrentPage(0);
                          }}
                        />
                        {Dark === "lightMode" ? (
                          <img src={searchicon} alt="" />
                        ) : (
                          <img src={ic_search_dark} alt="" />
                        )}
                      </div>
                      <div className="entries-wrapper">
                        <p>Entries per page</p>
                        <select
                          className="form-select select-drop-icon"
                          aria-label="Default select example"
                          onChange={(e) => {
                            setUsersPerPage(e.target.value);
                            setCurrentPage(0);
                          }}
                          value={usersPerPage && usersPerPage}
                        >
                          <option value="5">5</option>
                          <option value="10">10</option>
                          <option value="15">15</option>
                          <option value="20">20</option>
                          <option value="25" s selected>
                            25
                          </option>
                        </select>
                      </div>
                      <div className="booksIcon">
                        {excelLoading ? (
                          <ExcelLoader />
                        ) : (
                          <img
                            src={BookIcon}
                            onClick={(e) => downloadExcelFile(e)}
                            className="innerIconsBtn"
                            alt=""
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="table-wrapper" style={{ height: "60vh" }}>
                    <table id="customTable">
                      <thead>
                        <tr className="firstTabelRow">
                          <th>Sr.No</th>
                          <th>Employee Name</th>
                          <th>Activity</th>
                          <th>Work Date</th>
                          <th>Work Hour</th>
                        </tr>
                      </thead>
                      <tbody>
                        {searchValue === "" ? displayData : displaySearchedData}
                        {/*
                        <tr>
                        <td></td>
                        <td></td>
                        <td className="d-flex justify-content-end">
                          <span className="txtBoldTotal">Total Hours =</span>
                        </td>
                        <td>
                          <span className="txtBoldTotal">
                            {totalHours && totalHours}
                          </span>
                        </td>
                      </tr>
                    */}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <>
                  <NoData />
                </>
              )}
            </>
          )}
          <div style={{ display: "flex", alignItems: "center" }}>
            {selectedData.length > 0 && (
              <div>
                <span className="txtBoldTotal">Total Hours =</span>{" "}
                <span className="txtBoldTotal">{totalHours && totalHours}</span>
              </div>
            )}
            <div style={{ marginTop: "60px" }}>
              {!loading && searchedDataPageCounts.length > usersPerPage && (
                <Pagination
                  data={selectedData}
                  pageChangeHandler={pageChangeHandler}
                  usersPerPage={usersPerPage}
                  currentPage={currentPage}
                  searchValue={searchValue}
                  searchedDataPageCounts={searchedDataPageCounts}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectTimesheetDetails;
