import { useContext, useEffect, useState } from "react"
import Loader from "../loader"
import NoDataFound from "../NodataFound"
import { AppContext } from "../../../context/AppContext"
import { simpleGetCallWithErrorResponse } from "../../../api/ApiServices"
import ApiConfig from "../../../api/ApiConfig"
import { useParams } from "react-router-dom"
import Pagination from "../../../sharedComponent/Pagination"


const UserLikeDislike = () => {

    const { sidebar, selectedDomainId, domainName } = useContext(AppContext)

    const [userlist, setUserList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [search, setSearch] = useState("")
    const [isLike, setIsDislike] = useState(false)

    const [perPageData, setPerPageData] = useState(25)
    const [currentPage, setCurrentPage] = useState(0)
    const startIndex = currentPage * perPageData;
    const endIndex = startIndex + parseInt(perPageData);

    const { submodule, question } = useParams()

    useEffect(() => {

        getUserFeedback()

    }, [selectedDomainId, isLike])


    const getUserFeedback = () => {

        setIsLoading(true)

        simpleGetCallWithErrorResponse(ApiConfig.GET_FEEDBACK_USER + `domain_id=${selectedDomainId}&sub_module=${submodule}&preference=${isLike}&question_likes_text=${question}`).then((res) => {


            if (res?.json?.success) {
                setUserList(res?.json?.data)
            }
            setIsLoading(false)

        })

    }

    return <>
        <div className="main_container">

            <div
                className={sidebar ? "taskMain " : "cx-active taskMain"}
                id="cx-main"
            >


                <div >
                    <div className="role-content">
                        <div className="sub-header-role d-flex justify-content-between">
                            <h6 className="mt-2">User Feedback</h6>

                            <h6 className="mt-2 mr-2">Domain Name : {domainName}</h6>
                        </div>
                        <div className="d-flex mt-4">
                            <div className="input_wraper  ">
                                <input
                                    placeholder="Search By User Name"
                                    type="text"
                                    className=""
                                    style={{ width: '250px', height: "38px" }}
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />


                            </div>
                            <div className="d-flex">
                                <p style={{ width: '320px', color: 'grey', marginLeft: '5px', marginTop: '5px' }}>Entries per page</p>
                                <select
                                    class="form-select tasKCategory1 remove-border"
                                    aria-label="Default select example"
                                    value={perPageData}
                                    onChange={(e) => setPerPageData(e.target.value)}
                                >
                                    <option selected value={'25'}>25</option>
                                    <option value="50">50</option>
                                    <option value="75">75</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                            <div className="switch-wrapper form-check form-switch mt-4 ">

                                <input
                                    class="form-check-input mb-2 switch-whitebtn"
                                    type="checkbox"
                                    role="switch"
                                    id="flexSwitchCheckDefault"
                                    checked={isLike}
                                    onClick={() => setIsDislike(!isLike)}

                                ></input>
                                <label htmlFor="" className="mb-2" style={{ fontSize: "20px" }}>
                                    Like User
                                </label>
                            </div>
                        </div>
                        {
                            userlist?.length > 0 && <div className='bg-white p-3 rounded-3 mt-4 p-4'>
                                <h5><span className="text-secondary">Question :</span> {userlist[0]?.question_likes_text}</h5>
                                <h5 className="mt-2"><span className="text-secondary">Answers :</span> {userlist[0]?.answer}</h5>
                            </div>
                        }

                        <div className="table-wrapper table-wrapper-2" style={{ height: "600px" }}>
                            {
                                isLoading ? <Loader /> :
                                    userlist.length > 0 ?

                                        <table style={{ marginBottom: "20px" }}>
                                            <thead className="">
                                                <tr className="firstTabelRow">
                                                    <th>Sr. no</th>
                                                    <th>User Name</th>
                                                    <th>Date & </th>
                                                </tr>
                                            </thead>
                                            {userlist?.filter((user) => search?.length ? user?.question_user?.toLowerCase()?.includes(search?.toLowerCase()) : user)
                                                .slice(startIndex, endIndex).map((item, index) => (
                                                    < tr key={index} className="table-row-custom" >
                                                        <td>{startIndex + index + 1}</td>
                                                        <td >{item?.question_user}</td>
                                                        <td>{item?.date}</td>


                                                    </tr>
                                                ))}
                                        </table> : <NoDataFound />
                            }
                        </div>
                        <div className="row mt-4">
                            <div className="col-lg-12">
                                <Pagination
                                    data={userlist}
                                    pageChangeHandler={(page) => setCurrentPage(page.selected)}
                                    usersPerPage={perPageData}
                                    currentPage={currentPage}
                                    searchValue={search}
                                //searchedDataPageCounts={searchedDataPageCounts}
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div >
    </>

}

export default UserLikeDislike