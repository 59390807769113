import { AppContext } from "../../../context/AppContext";
import React, { useState, useRef, useEffect } from "react";
import { useContext } from "react";
import Loader from "../loader";
import NoDataFound from "../NodataFound";
import { Link, useNavigate, useParams } from "react-router-dom";
import addedit from "../../../assets/images/Editac.svg";
import { Form, Modal } from "react-bootstrap";
import add from "../../../assets/images/check.svg";
import close from "../../../assets/images/close.svg";
import uploadicon from "../../../assets/images/plus_officecaller.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ApiConfig, { BASE_URL_CHATADMIN } from "../../../api/ApiConfig";
import { ToastMsg } from "../../TostMsg";
import { simpleGetCallWithErrorResponse, simplePostCall, multipartPostCallWithErrorResponse, PostCallWithErrorResponse, postMultipartRequestAdmin } from "../../../api/ApiServices";



const SubModuleDataStat = () => {
  const { sidebar, selectedDomainId } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(true);


  const { moduleName } = useParams()

  const [editId, setEditId] = useState('')
  const [isAutoUpdate, setIsAutoUpdate] = useState(false)

  const [subModuleList, setSubModuleList] = useState([]);
  const [isIlstrVies, setIsIlstrView] = useState(false)
  const [ilstrViewUrl, setIlstrViewUrl] = useState('')

  const navigate = useNavigate()

  const istrPreviewClose = () => {
    setIlstrViewUrl('')
    setIsIlstrView(false)

  }

  const initialModule = {
    sub_module_by_display_name: '',
    sub_module_by_description: "",
    sub_module_by_name: "",
    update_icon_file: '',
    sub_module_icon: '',
    illustration_pic: '',
    illustration_file: ''
  }

  const [editModule, setEditModule] = useState(initialModule)

  const ref = useRef(null)
  const uploadIllustrationRef = useRef()


  const handleUploadIcon = () => {
    ref.current.click()

  }

  const handlUploadIlstn = () => {
    uploadIllustrationRef.current.click()
  }

  const handleEditModule = (key, val) => {

    setEditModule(prev => ({
      ...prev,
      [key]: val
    }))

  }


  const getSubModuleList = () => {
    simpleGetCallWithErrorResponse(ApiConfig.GET_SUB_MODULE_LIST + `domain_id=${selectedDomainId}&module_name=${moduleName}`).then((res) => {


      if (res.json.success) {

        setIsLoading(false)

        setSubModuleList(res.json.data)

      }

    })

  }


  useEffect(() => {

    getSubModuleList()

  }, [selectedDomainId])

  const handleUpdate = () => {

    if (isAutoUpdate) return

    setIsAutoUpdate(true)


    editModule.domain_id = selectedDomainId

    const formdata = new FormData()

    formdata.append('sub_module_by_display_name', editModule.sub_module_by_display_name)

    formdata.append('sub_module_by_name', editModule.sub_module_by_name)

    formdata.append('domain_id', selectedDomainId)

    //formdata.append('module_by_description', '')

    formdata.append('sub_module_icon', editModule.sub_module_icon)


    formdata.append('update_icon_file', editModule.update_icon_file)

    formdata.append('illustration_pic', editModule.illustration_pic)


    formdata.append('illustration_file', editModule.illustration_file)



    multipartPostCallWithErrorResponse(ApiConfig.UPDATE_SUB_MODULE_DISPLAY_NAME, formdata).then((res) => {

      console.log(res);

      if (res?.json?.success) {
        ToastMsg('success', res?.json?.message)
        getSubModuleList()
        setEditId('')
        setIsAutoUpdate(false)
        setEditModule(initialModule)
      } else {

        setIsAutoUpdate(false)

        ToastMsg('error', res?.json.message)

      }

    })

  }

  const handleEditClick = () => {
    setEditModule(prev => ({
      ...prev,
      sub_module_by_display_name: module?.sub_module_by_display_name || '',
      sub_module_icon: module?.sub_module_icon,
      sub_module_by_name: module?.intent_part
    }));
    setEditId(module?.sub_module_by_id);
  };



  return (
    <div className={sidebar ? "taskMain" : "cx-active taskMain"} id="cx-main">
      <div>
        <div className="role-content">
          <div className="sub-header-role d-flex justify-content-between">
            <h6 className="mt-2">{moduleName}  Stat</h6>
            {/* <button className="addSetting_btn" onClick={handleShowadd}>
              Add +
            </button> */}
          </div>
          <div
            className="table-wrapper table-wrapper-2"
            style={{ height: "600px" }}
          >
            {
              isLoading ? <Loader /> :
                subModuleList.length > 0 ?
                  <table style={{ marginBottom: "20px" }}>
                    <thead className="">
                      <tr className="firstTabelRow">
                        <th>Sr. No</th>
                        <th>Sub Module Name</th>
                        <th>Icons</th>
                        <th>Illustration</th>
                        <th>Display Name</th>
                        <th>Total Intents</th>
                        <th>Total Questions</th>
                        <th>Publish Intents</th>
                        <th>Publish Questions</th>
                        <th>Non-Publish Intents</th>
                        <th>Non-Publish Questions</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    {subModuleList.map((module, index) => (
                      <tr key={index} className="table-row-custom"
                      >
                        <td>{index + 1}</td>
                        <td>
                          <Link to={'/topics/' + module.intent_part}>{module.intent_part}</Link>
                        </td>
                        <td>
                          {
                            editId == module.sub_module_by_id ?

                              <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 100, hide: 300 }}
                                overlay={(props) => (
                                  <Tooltip id="button-tooltip" {...props}>
                                    {
                                      module.sub_module_icon ? "Change Icon" : "Add icon"
                                    }
                                  </Tooltip>
                                )}
                              >
                                <img src={editModule.update_icon_file ? URL.createObjectURL(editModule.update_icon_file) :
                                  module.sub_module_icon ? BASE_URL_CHATADMIN + module.sub_module_icon : uploadicon}
                                  style={{ width: '20px' }}
                                  onClick={handleUploadIcon}
                                  onError={(e) => e.target.src = uploadicon}
                                />
                              </OverlayTrigger> : <img src={

                                module.sub_module_icon ? BASE_URL_CHATADMIN + module.sub_module_icon : uploadicon}
                                style={{ width: '20px' }}
                                onError={(e) => e.target.src = uploadicon}
                              />
                          }
                          <div class="image-upload">
                            <input id="file-input" type="file" ref={ref}
                              onChange={(e) => handleEditModule('update_icon_file', e.target.files[0])}
                              accept="image/*"
                            />
                          </div>
                          <div class="image-upload">
                            <input id="file-input" type="file" ref={uploadIllustrationRef}
                              onChange={(e) => handleEditModule('illustration_file', e.target.files[0])}
                              accept="image/*"
                            />
                          </div>
                        </td>
                        <td>
                          {
                            editId == module.sub_module_by_id ?

                              <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 100, hide: 300 }}
                                overlay={(props) => (
                                  <Tooltip id="button-tooltip" {...props}>
                                    {
                                      module.illustration_pic ? "Change Icon" : "Add icon"
                                    }
                                  </Tooltip>
                                )}
                              >
                                <img src={editModule.illustration_file ? URL.createObjectURL(editModule.illustration_file) :
                                  module.illustration_pic ? BASE_URL_CHATADMIN + module.illustration_pic : uploadicon}
                                  style={{ width: '20px' }}
                                  onClick={handlUploadIlstn}
                                  onError={(e) => e.target.src = uploadicon}
                                />
                              </OverlayTrigger> : <img src={

                                module.illustration_pic ? BASE_URL_CHATADMIN + module.illustration_pic : uploadicon}
                                style={{ width: '20px' }}
                                //onError={(e) => e.target.style.display = 'none'}
                                onClick={() => {

                                  if (!module.illustration_pic) return
                                  setIlstrViewUrl(module.illustration_pic)
                                  setIsIlstrView(true)

                                }}
                              />
                          }
                        </td>
                        <td>
                          {
                            editId == module.sub_module_by_id ?
                              <input type="text" className="form-control"
                                value={editModule.sub_module_by_display_name}
                                onChange={(e) => handleEditModule('sub_module_by_display_name', e.target.value)}
                              /> : module.sub_module_by_display_name ? module.sub_module_by_display_name : module.intent_part
                          }
                        </td>
                        <td>{Number(module.publish_intent_count) + Number(module.unpublish_intent_count)}</td>
                        <td>{Number(module.publish_question_count) + Number(module.unpublish_question_count)}</td>
                        <td>{module.publish_intent_count}</td>
                        <td>{module.publish_question_count}</td>
                        <td>{module.unpublish_intent_count}</td>
                        <td>{module.unpublish_question_count}</td>
                        <td>
                          {
                            editId == module.sub_module_by_id ?

                              <>
                                <OverlayTrigger
                                  placement="bottom"
                                  delay={{ show: 100, hide: 300 }}
                                  overlay={(props) => (
                                    <Tooltip id="button-tooltip" {...props}>
                                      Save
                                    </Tooltip>
                                  )}
                                >
                                  <img src={add}
                                    onClick={handleUpdate}
                                  />
                                </OverlayTrigger>

                                <OverlayTrigger
                                  placement="bottom"
                                  delay={{ show: 100, hide: 300 }}
                                  overlay={(props) => (
                                    <Tooltip id="button-tooltip" {...props}>
                                      cancel
                                    </Tooltip>
                                  )}
                                >
                                  <img
                                    src={close}
                                    onClick={() => {
                                      setEditModule(initialModule)
                                      setEditId('')
                                    }}
                                  />
                                </OverlayTrigger>
                              </> :
                              <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 100, hide: 300 }}
                                overlay={(props) => (
                                  <Tooltip id="button-tooltip" {...props}>
                                    Edit
                                  </Tooltip>
                                )}
                              >
                                <img
                                  src={addedit}
                                  onClick={() => {

                                    setEditModule(prev => ({
                                      ...prev,
                                      sub_module_by_display_name: module.sub_module_by_display_name ? module.sub_module_by_display_name : '',

                                      sub_module_icon: module.sub_module_icon,
                                      sub_module_by_name: module.intent_part,
                                      illustration_pic: module.illustration_pic
                                    }))

                                    setEditId(module.sub_module_by_id)

                                  }}
                                />
                              </OverlayTrigger>
                          }

                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 100, hide: 300 }}
                            overlay={(props) => (
                              <Tooltip id="button-tooltip" {...props}>
                                UserFeed Back
                              </Tooltip>
                            )}
                          >
                            <img src={add}
                              onClick={() => navigate('/userfeddback/' + module?.intent_part)}
                            />
                          </OverlayTrigger>
                        </td>
                      </tr>
                    ))}

                  </table>
                  : <NoDataFound />
            }
          </div>
        </div>
      </div>

      <Modal
        className="delete-news-modal"
        show={isIlstrVies}
        onHide={istrPreviewClose}
        dialogClassName="modal-100w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="delete-title">Illustration Preview</Modal.Title>
        </Modal.Header>
        <div className="lineSeperate"></div>

        <img src={BASE_URL_CHATADMIN + ilstrViewUrl} alt=""
          className="m-4 rounded"
        />


      </Modal>
    </div>
  );
};
export default SubModuleDataStat;
