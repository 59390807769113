import "../../assets/css/main.scss";
import ApiConfig from "../../api/ApiConfig";
import BackDropLoader from "../../sharedComponent/BackDropLoader";
import Beatloader from "../../sharedComponent/Beatloader";
import CalendarBox from "./CalendarBox";
import MyTask from "./MyTask";
import Nav from "react-bootstrap/Nav";
import ProgressCircle from "./ProgressCircle";
import React, { useContext, useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";

import alert_icon_1 from "../../assets/images/alert_icon_1.svg";

import check_in_icon from "../../assets/images/check_in_icon.svg";
import check_out_icon from "../../assets/images/check_out_icon.svg";
import checked_icon from "../../assets/images/checked_icon.svg";
import location_icon from "../../assets/images/location_icon.svg";
import moment from "moment";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { AppContext } from "../../context/AppContext";
import { useLocation, Link } from "react-router-dom";
import approved from "../../assets/images/icons/icons (2)/approved.svg";
import submitted from "../../assets/images/icons/icons (2)/submitted.svg";
import rejected from "../../assets/images/icons/icons (2)/rejected.svg";


import {
  PostCallWithErrorResponse,
  simpleGetCallWithToken,
} from "../../api/ApiServices";

import {
  formatTimes,

  getDateddmmyyyyInDash,
} from "../../common";
import { ToastMsg } from "../TostMsg";

const Dashboard = () => {
  const { sidebar, userData, setOpenSessionLogoutModal, permissions } =
    useContext(AppContext);
  const currentRoute = useLocation().pathname;
  const [loading, setLoading] = useState(false);
  const handleClose = () => setShow(false);
  const handleCloseNo = () => {

    setShowResone(true)
    setShow(false)
  };
  const handleShow = () => setShow(true);
  // States
  const [show, setShow] = useState(true);
  const [ShowResone, setShowResone] = useState(false);

  const [punchInPunchOutTime, setPunchInPunchOutTime] = useState({});
  const [currentHours, setCurrentHours] = useState(0);

  const [timer, setTimer] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const [isValided, setIsValided] = useState(false)

  const [secondsElapsed, setSecondsElapsed] = useState(0);
  const [addTaskPayload, setAddTaskPayload] = useState({
    ProjectId: 0,

  });
  const [state, setState] = useState({
    ProjectId: 0,
  })



  const addOrUpdateTask = async (e) => {
    try {
      e.preventDefault();

      setBtnLoading(true);
      const response = await PostCallWithErrorResponse(
        ApiConfig.ADD_TASK,
        JSON.stringify(addTaskPayload)
      );
      if (response.error) {
      } else if (response.json.Success === true) {
        ToastMsg("success", response.json.Message);
        setBtnLoading(false);

        getTaskList();
      } else {
        ToastMsg("error", response.json.Message);
        setBtnLoading(false);
        console.log("error response,", response);

      }
    } catch (error) {
      console.log("catch error,", error);
    } finally {
      setBtnLoading(false);
    }
  };

  // for test commint
  // const CheckInCheckOut = () => {
  //   setLoading(true);
  //   // if (navigator.geolocation) {
  //   navigator.geolocation.getCurrentPosition(
  //     (position) => {
  //       const latitude = position.coords.latitude.toString();
  //       const longitude = position.coords.longitude.toString();

  //       let payLoad = {
  //         Lat: latitude,
  //         Long: longitude,
  //         MacId: "1",
  //         Platform: "WebApp",
  //         Status: "PendingForApproval",
  //         NetworkSource: "Wifi",
  //       };

  //       PostCallWithErrorResponse(
  //         ApiConfig.GETINOUT_TIME_C,
  //         JSON.stringify(payLoad)
  //       )
  //         .then((res) => {
  //           setLoading(false);
  //           if (res.json.IsCallSuccessful === true) {
  //             console.log(res);
  //             setPunchInPunchOutTime(res.json);
  //             getCheckInCheckOutData();
  //             ToastMsg("success", res.json.Message);
  //             // handleTimer(); // Start or stop the timer based on the response
  //           } else {
  //             console.log("error res,", res);
  //             ToastMsg("error", res.json.Message);
  //           }
  //         })
  //         .catch((err) => {
  //           setLoading(false);
  //           console.error(err);
  //           console.log(err.message);
  //         });
  //     },
  //     (error) => {
  //       setLoading(false);
  //       console.error(error);
  //       console.log(error.message);
  //     }
  //   );
  //   // }
  //   // else {
  //   //   setLoading(false);
  //   //   console.log("Geolocation is not supported by this browser.");
  //   // }
  // };

  // punch in and punch out funtionalty

  const calculateTimeDifference = (startTimeString) => {
    const startTime = new Date();
    const [time, modifier] = startTimeString.split(" ");
    let [hours, minutes] = time.split(":");

    if (modifier === "PM" && hours !== "12") {
      hours = parseInt(hours, 10) + 12;
    }
    if (modifier === "AM" && hours === "12") {
      hours = 0;
    }

    startTime.setHours(hours, minutes, 0, 0);

    const currentTime = new Date();
    const timeDifference = Math.floor((currentTime - startTime) / 1000); // Difference in seconds

    return timeDifference;
  };

  const handleTimer = () => {
    if (punchInPunchOutTime?.InTime && !punchInPunchOutTime?.OutTime) {
      setSecondsElapsed(calculateTimeDifference(punchInPunchOutTime?.InTime));
      const newTimer = setInterval(() => {
        setSecondsElapsed((prev) => prev + 1);
      }, 1000);
      setTimer(newTimer);
    } else if (punchInPunchOutTime?.OutTime) {
      clearInterval(timer);
      setTimer(null);
    }
  };

  const formatTime = (seconds) => {
    const h = String(Math.floor(seconds / 3600)).padStart(2, "0");
    const m = String(Math.floor((seconds % 3600) / 60)).padStart(2, "0");
    const s = String(seconds % 60).padStart(2, "0");
    return `${h}:${m}:${s}`;
  };
  const handleGeolocationSuccess = (position) => {
    const latitude = position.coords.latitude.toString();
    const longitude = position.coords.longitude.toString();

    const payLoad = {
      Lat: latitude,
      Long: longitude,
      MacId: "1",
      Platform: "WebApp",
      Status: "PendingForApproval",
      NetworkSource: "Wifi",

    };

    PostCallWithErrorResponse(
      ApiConfig.GETINOUT_TIME_C,
      JSON.stringify(payLoad)
    )
      .then((res) => {
        setLoading(false);
        if (res.json.IsCallSuccessful === true) {
          console.log(res);
          setPunchInPunchOutTime(res.json);
          getCheckInCheckOutData();
          ToastMsg("success", res.json.Message);
        } else {
          console.log("error res,", res);
          ToastMsg("error", res.json.Message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        ToastMsg("error", err.message || "An error occurred.");
      });
  };

  const handleGeolocationError = (error) => {
    setLoading(false);
    console.error(error);
    switch (error.code) {
      case error.PERMISSION_DENIED:
        ToastMsg("error", "User denied the request for Geolocation.");
        break;
      case error.POSITION_UNAVAILABLE:
        ToastMsg("error", "Location information is unavailable.");
        break;
      case error.TIMEOUT:
        ToastMsg("error", "The request to get user location timed out.");
        break;
      default:
        ToastMsg("error", "An unknown error occurred.");
        break;
    }
  };

  const initiateCheckInCheckOut = () => {
    setLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        handleGeolocationSuccess,
        handleGeolocationError
      );
    } else {
      setLoading(false);
      ToastMsg("error", "Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    // getCheckInCheckOutData();
  }, []);
  useEffect(() => {
    handleTimer();

    return () => {
      clearInterval(timer);
    };
  }, [punchInPunchOutTime, timer]);

  const buttonText = punchInPunchOutTime.InTime
    ? punchInPunchOutTime.OutTime
      ? "DONE"
      : "Punch Out"
    : "Punch In";

  const isButtonDisabled =
    !!punchInPunchOutTime.InTime && !!punchInPunchOutTime.OutTime;

  const buttonStyles = {
    backgroundColor: punchInPunchOutTime.InTime
      ? punchInPunchOutTime.OutTime
        ? "grey"
        : "red"
      : "green",
    color: punchInPunchOutTime.InTime
      ? punchInPunchOutTime.OutTime
        ? "black"
        : "white"
      : "white",
    cursor: isButtonDisabled || loading ? "not-allowed" : "pointer",
  };

  const punchInHours = (secondsElapsed / 3600).toFixed(2);

  const [alertnotifications, setAlertnotifications] = useState([]);
  const [timesheetCounts, setTimesheetCounts] = useState({});
  const [timesheetCounts2, setTimesheetCounts2] = useState({});
  const [timesheetCounts4, setTimesheetCounts4] = useState({});

  const [checkInCheckOutTime, setCheckInCheckOutTime] = useState({});

  // States

  const convertTimeInAMPMFormat = (date) => {
    let splittedTime = date.split("T")[1];
    let hours = Number(splittedTime.split(":")[0]);
    let calculatedHrs = hours > 12 ? hours - 12 : hours;
    let hours1 = calculatedHrs < 10 ? `${"0"}${calculatedHrs}` : calculatedHrs;
    let timeInAMPM = `${splittedTime?.replace(
      splittedTime.slice(0, 2),
      hours1
    )} ${hours < 12 ? "AM" : "PM"}`;

    return timeInAMPM;
  };
  // TIMESHEETCOUNTS
  const token = localStorage?.Token && localStorage?.Token;

  const getTimesheetCounts = () => {
    simpleGetCallWithToken(ApiConfig.TIMESHEETCOUNTS)
      .then((res) => {
        if (res.Success === true) {
          setTimesheetCounts(res.Data);
        } else {
          console.log("error response,", res);
        }
      })
      .catch((error) => {
        // console.log("error,", error);
      });
  };

  const getTimesheetCounts2 = (month, year) => {
    PostCallWithErrorResponse(
      ApiConfig.TIMESHEETCOUNTS2,
      JSON.stringify({
        year: year ? year : new Date().getFullYear(),
        Month: month ? month : new Date().getMonth() + 1,
      })
    )
      .then((res) => {
        if (res.Success === true) {
          setTimesheetCounts2(res.Data);
        } else {
          console.log("error response,", res);
        }
      })
      .catch((error) => {
        // console.log("error,", error);
      });
  };

  const getCheckInCheckOutData = () => {
    setLoading(true);
    PostCallWithErrorResponse(
      ApiConfig.GET_CHECKIN_CHECKOUT_DATA,
      JSON.stringify({
        EmpId: Number(userData.EmpId),
        OrgId: Number(userData.OrgId),

        date: new Date(),
      })
    )
      .then((res) => {
        setLoading(false);
        if (res?.json?.Success === true) {
          console.log(res);
          if (res?.json?.Data) setPunchInPunchOutTime(res?.json?.Data);
        } else {
          console.log("error res,", res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const CheckInCheckOut = () => {
  //   setLoading(true);
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //         (position) => {
  //           const latitude = position.coords.latitude.toString();
  //           const longitude = position.coords.longitude.toString();

  //             let payLoad = {
  //                 Lat:latitude,
  //                 Long:longitude,
  //                 MacId: "1",
  //                 Platform: "WebApp",
  //                 Status: "PendingForApproval",
  //                 NetworkSource: "Wifi",
  //             };

  //             PostCallWithErrorResponse(ApiConfig.GETINOUT_TIME_C, JSON.stringify(payLoad))
  //                 .then((res) => {
  //                     setLoading(false);
  //                     if (res.json.IsCallSuccessful === true) {
  //                         console.log(res);
  //                         setCheckInCheckOutTime(res.json);
  //                         getCheckInCheckOutData();
  //                     } else {
  //                         console.log("error res,", res);
  //                     }
  //                 })
  //                 .catch((err) => {
  //                     setLoading(false);
  //                     console.error(err);
  //                     console.log(err.message);
  //                 });
  //         },
  //         (error) => {
  //             setLoading(false);
  //             console.error(error);
  //             console.log(error.message);
  //         }
  //     );
  // } else {
  //     setLoading(false);
  //     console.log("Geolocation is not supported by this browser.");
  // }
  //   // let payLoad = {
  //   //   Lat: ,
  //   //   Long: ,
  //   //   MacId: "1",
  //   //   Platform: "WebApp",
  //   //   Status: "PendingForApproval",
  //   //   NetworkSource: "Wifi",
  //   // };
  //   // PostCallWithErrorResponse(
  //   //   ApiConfig.GETINOUT_TIME_C,
  //   //   JSON.stringify(payLoad)
  //   // )
  //   //   .then((res) => {

  //   //     setLoading(false);
  //   //     if (res.json.IsCallSuccessful === true) {
  //   //       console.log(res);

  //   //       setCheckInCheckOutTime(res.json);
  //   //       getCheckInCheckOutData()
  //   //     } else {
  //   //       console.log("error res,", res);
  //   //     }
  //   //   })
  //   //   .catch((err) => {
  //   //     console.log(err);
  //   //   });
  // };

  const [notificationLoader, setNotificationLoader] = useState(false);
  //DASHBOARD_ALERT_NOTIFICATIONS
  const getAlertNotifications = () => {
    setNotificationLoader(true);
    PostCallWithErrorResponse(ApiConfig.DASHBOARD_ALERT_NOTIFICATIONS)
      .then((res) => {
        setNotificationLoader(false);
        if (res.json.Success === true) {
          setAlertnotifications(res.json.Data);
          // getCheckInCheckOutData()
        } else {
          setNotificationLoader(false);
        }
      })
      .catch((err) => {
        setNotificationLoader(false);
        console.log(err);
      });
  };

  const [taskLoader, setTaskLoader] = useState(false);
  const [taskList, setTaskList] = useState([]);
  const [pendingTask, setPendingTask] = useState([]);
  const [ProgressData, setProgressData] = useState([]);
  const [completedData, setCompletedData] = useState([]);

  const getProgressData = () => {
    simpleGetCallWithToken(ApiConfig.GET_INPROGRESS_DATA_LIST)
      .then((res) => {
        if (res.Success === true) {
          setProgressData(res.Data);
        }
      })
      .catch((err) => {
        // console.log(err)
      });
  };

  const getCompletedData = () => {
    simpleGetCallWithToken(ApiConfig.GET_COMPLETED_DATA_LIST)
      .then((res) => {
        if (res.Success === true) {
          setCompletedData(res.Data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTaskList = () => {
    setTaskLoader(true);
    let payLoad = {
      EmpId: Number(userData.EmpId),
      Orgid: Number(userData.OrgId),
    };
    PostCallWithErrorResponse(ApiConfig.GET_TASK_LIST, JSON.stringify(payLoad))
      .then((res) => {
        setTaskLoader(false);
        if (res.json.Success && res.json.Data !== null) {
          let approvedTask = res.json.Data.filter((item, i) => {
            return item.status === "9";
          });
          setTaskList(approvedTask);

          let filteredData = res.json.Data.filter((item, i) => {
            return item.status === "4";
          });
          setPendingTask(filteredData);
        } else {
          console.log("error res,", res);
        }
      })
      .catch((error) => {
        console.log("error response,", error);
      });
  };

  const getTotalWorkHours = (year, month) => {
    setTaskLoader(true);
    let Year = year ? year : new Date().getFullYear();
    let Month = month ? month : new Date().getMonth() + 1;
    let url = `${ApiConfig.GET_TOTAL_WORK_HOURS}?month=${Month}&year=${Year}`;
    PostCallWithErrorResponse(url)
      .then((res) => {
        setTaskLoader(false);
        console.log("total Work hours--->", res.json);
        setTimesheetCounts4(res?.json?.Data);
      })
      .catch((error) => {
        console.log("error response,", error);
      });
  };

  // const buttonText = punchInPunchOutTime.InTime
  //   ? punchInPunchOutTime.OutTime
  //     ? "DONE"
  //     : "Punch Out"
  //   : "Punch In";

  // const isButtonDisabled =
  //   !!punchInPunchOutTime.InTime && !!punchInPunchOutTime.OutTime;

  // const buttonStyles = {
  //   backgroundColor: punchInPunchOutTime.InTime
  //     ? punchInPunchOutTime.OutTime
  //       ? "grey"
  //       : "red"
  //     : "green",
  //   color:punchInPunchOutTime.InTime
  //     ? punchInPunchOutTime.OutTime
  //       ? "black"
  //       : "white"
  //     : "white",
  //   cursor: isButtonDisabled || loading ? "not-allowed" : "pointer",
  // };

  useEffect(() => {
    if (token && token) {
      const decode = JSON.parse(atob(token && token?.split(".")[1]));
      if (decode.exp * 1000 < new Date().getTime()) {
        setOpenSessionLogoutModal(true);
      } else {
        getCheckInCheckOutData();
        getAlertNotifications();
        getTimesheetCounts();
        getTimesheetCounts2();
        getTaskList();
        getProgressData();
        getCompletedData();
        getTotalWorkHours();
      }
    }
  }, []);

  const alertIcon = (icon) => {
    switch (icon) {
      case "Punched in":
        return alert_icon_1;
      case "Approved":
        return approved;
      case "Submitted":
        return submitted;
      case "Rejected":
        return rejected;
      case "Punched-in Late":
        return alert_icon_1;
      case "Punched Out":
        return alert_icon_1;
      case "Punched-Out Early":
        return alert_icon_1;
      case "Unknown":
        return alert_icon_1;
      case "Announcement":
        return alert_icon_1;
      case "assigned":
        return alert_icon_1;
      case "Hold":
        return alert_icon_1;
      default:
        return null;
    }
  };

  return (
    <>
      {loading && <BackDropLoader />}
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="row d-flex justify-content-around">
          <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="dashboard-heading">
              <h3 className="mt-2">
                Hello, {userData.FirstName ? userData?.FirstName : ""}
              </h3>
            </div>
          </div>
        </div>

        <div className="row ">
          <div className="col-xxl-8 col-xl-8 col-lg-8 col-xs-8 h-100">
            {permissions && permissions["View Calendar"] && (
              <CalendarBox />
            )}
          </div>
          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 h-100">
            {permissions && permissions["Check In / Out"] && (
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 h-100">
                <div className="check-in-out">
                  <div className="heading">
                    <h5>Mark Attendance</h5>
                  </div>
                  <div className="location-info text-center">
                    <span>
                      <img src={location_icon} alt="" className="mx-2" />
                      Location&nbsp; : &nbsp;
                    </span>
                    <span>
                      {punchInPunchOutTime?.LocationName
                        ? punchInPunchOutTime?.LocationName
                        : ""}
                    </span>
                  </div>
                  <div className="d-flex justify-content-center location-info">
                    <div style={{ height: "80px", width: "80px" }}>
                      <CircularProgressbar
                        value={
                          punchInPunchOutTime?.TotalHours
                            ? punchInPunchOutTime?.TotalHours
                            : secondsElapsed
                        }
                        text={
                          punchInPunchOutTime?.TotalHours
                            ? `${punchInPunchOutTime?.TotalHours}.00`
                            : `${formatTime(secondsElapsed)}`
                        }
                        maxValue={32400}
                        styles={buildStyles({
                          rotation: 0,
                          strokeLinecap: "butt",
                          textSize: "16px",
                          pathColor: `#53B7EA`,
                          textColor: "#53B7EA",
                          trailColor: "#d6d6d6",
                          backgroundColor: "#3e98c7",
                        })}
                      />
                    </div>
                  </div>

                  <div className="d-flex justify-content-center mb-2">
                    <button
                      onClick={initiateCheckInCheckOut}
                      className="save-btn-permission save-btn-permission-arbaz-responsive punchInButton"
                      style={buttonStyles}
                      disabled={isButtonDisabled || loading}
                    >
                      {buttonText}
                    </button>
                  </div>

                  <div className="check-in-out-details">
                    <div className="row text-center">
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <div className="check-in">
                          <p>
                            <img src={check_in_icon} alt="" />
                            {
                              <p className="time">
                                {punchInPunchOutTime &&
                                  punchInPunchOutTime?.InTime
                                  ? punchInPunchOutTime?.InTime
                                  : "00:00"}
                              </p>
                            }

                            <p className="label">Check In</p>
                          </p>
                        </div>
                      </div>
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <div className="check-out">
                          <p>
                            <img src={check_out_icon} alt="" />
                            {
                              <p className="time">
                                {punchInPunchOutTime &&
                                  punchInPunchOutTime?.OutTime
                                  ? punchInPunchOutTime?.OutTime
                                  : "00:00"}
                              </p>
                            }
                            <p className="label">Check Out</p>
                          </p>
                        </div>
                      </div>

                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <div className="total-hours">
                          <p>
                            <img src={checked_icon} alt="" />
                            <p className="time">
                              {punchInPunchOutTime?.TotalHours} Hrs
                            </p>
                            <p className="label">Working Hours</p>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {permissions &&
              permissions["View Calendar"] &&
              permissions &&
              permissions["Alert Notification"] && (
                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3">
                  <div className="alerts-section">
                    <div className="heading">
                      <h5>Alerts</h5>
                    </div>

                    <div className="alert-items-wrapper">
                      {notificationLoader ? (
                        <Beatloader />
                      ) : (
                        alertnotifications &&
                        alertnotifications.length > 0 &&
                        alertnotifications.map((notification) => {
                          let notificationDate = moment(
                            new Date(
                              notification &&
                              notification?.date?.replace("T", " ")
                            )
                          ).format("LTS");
                          return (
                            <div className="alert-item">
                              <div className="alert-img">
                                <img
                                  src={alertIcon(notification?.Remark)}
                                  alt=""
                                />
                              </div>
                              <div className="alert-text">
                                <p>{notification?.Message}</p>
                                <span>
                                  {notification?.Date
                                    ? getDateddmmyyyyInDash(notification?.Date)
                                    : ""}
                                  &nbsp;
                                  {notification?.Date
                                    ? formatTimes(notification?.Date)
                                    : ""}
                                </span>
                              </div>
                            </div>
                          );
                        })
                      )}
                    </div>
                  </div>
                </div>
              )}
          </div>
          {permissions && !permissions["View Calendar"] && (
            <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12">
              {permissions && permissions["Alert Notification"] && (
                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 custom-height-70">
                  <div className="alerts-sectiond">
                    <div className="heading">
                      <h5>Alerts</h5>
                    </div>

                    <div className="alert-items-wrapper">
                      {notificationLoader ? (
                        <Beatloader />
                      ) : (
                        alertnotifications &&
                        alertnotifications.length > 0 &&
                        alertnotifications.map((notification) => {
                          let notificationDate = moment(
                            new Date(
                              notification &&
                              notification?.date?.replace("T", " ")
                            )
                          ).format("LTS");
                          return (
                            <div className="alert-item">
                              <div className="alert-img">
                                <img
                                  src={alertIcon(notification?.Remark)}
                                  alt=""
                                />
                              </div>
                              <div className="alert-text">
                                <p>{notification?.Message}</p>
                                <span>
                                  {notification?.Date
                                    ? getDateddmmyyyyInDash(notification?.Date)
                                    : ""}
                                  &nbsp;
                                  {notification?.Date
                                    ? formatTimes(notification?.Date)
                                    : ""}
                                </span>
                              </div>
                            </div>
                          );
                        })
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>



        <div className="row d-flex justify-content-around">
          <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-8 g-3">
            {permissions && permissions["My Task"] && (
              <div className="my-tasks-section">
                <div className="my-task-main-heading">
                  <h5 className="mt-2">My Task</h5>
                  {permissions && permissions["Add Task Dashboard"] && (
                    <Link
                      to="/tasklist"
                      className="my-task-create-btn"
                      variant="primary"
                    >
                      + Add Task{" "}
                    </Link>
                  )}
                </div>
                <Tab.Container
                  defaultActiveKey="1"
                  id="justify-tab-example"
                  className="mb-3 d-flex"
                  justify
                >
                  <div className="row d-flex align-items-center justify-content-around">
                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="my-task-heading-tabs-wrapper">
                        <Nav className="my-task-heading-tabs">
                          <Nav.Item className="tab-item">
                            <Nav.Link eventKey="1">
                              Assigned <span>{taskList.length}</span>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item className="tab-item">
                            <Nav.Link eventKey="2">
                              In Progress <span>{ProgressData.length}</span>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item className="tab-item">
                            <Nav.Link eventKey="3">
                              Pending Approval <span>{pendingTask.length}</span>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item className="tab-item">
                            <Nav.Link eventKey="4">
                              Completed <span>{completedData.length}</span>
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </div>
                    </div>

                    <MyTask
                      taskList={taskList}
                      pendingTask={pendingTask}
                      taskLoader={taskLoader}
                      ProgressData={ProgressData}
                      completedData={completedData}
                    />
                  </div>
                </Tab.Container>
              </div>
            )}
          </div>
          {permissions && permissions["Timesheet"] && (
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 g-3">
              <ProgressCircle
                timesheetCounts={timesheetCounts}
                timesheetCounts2={timesheetCounts2}
                timesheetCounts4={timesheetCounts4}
              />
            </div>
          )}
        </div>









        {/* Add Modal Task */}
        {/* <Modal
        size="xl"
        show={show}
        onHide={handleClose}
        aria-labelledby="modal-90w"
        className="add-task-modal-main"
        centered
      >
        <Modal.Header className="add-task-modal-header ">
          <Modal.Title id="example-modal-sizes-title-lg">Task Progress Update</Modal.Title>
          <div className="rightAxe">
            <img src={Axe} alt="" onClick={() => setShow(false)} />
          </div>
        </Modal.Header>
        <div className="lineSeperate"></div>
      
          <Modal.Body>
            <div className="mainContentmodel">
             
             
            <p
            style={{
              fontSize:"16px",
              fontWeight:"500"
            }}
            >Have you successfully completed the 'Debug API Integration for Vehicle Tracking' task assigned for Ghatge on 20/01/2025?</p>
            
              
                
            
         
            </div>
          </Modal.Body>

          <div className="lineSeperate"></div>
          <div className="d-flex justify-content-end align-items-center mainBtnsSub">
            <button
              type="button"
              className="btn-width cancelBtn mx-3"
              onClick={handleCloseNo}
            >
              No
            </button>
            <button
              className="btn-width saveBtn"
              disabled={btnLoading ? true : false}
              onClick={handleClose}

            >
              {btnLoading ? (
                <div className="d-flex justify-content-center align-items-center">
                  <ClipLoader color="#fff" size={18} className="me-2" />
                </div>
              ) : (
                "Yes"
              )}
            </button>
          </div>
       
      </Modal> */}

        {/* Task List */}
        {/* 
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={ShowResone}
        className="create-workflow-main modal-lg"
      >
        <Modal.Header>
          <Modal.Title>
            <label htmlFor="" className="create-workflow-text">
            Reason for Not Completing
            </label> 
        
          </Modal.Title>
          <img src={Axe} alt="" onClick={handleCloseResone} />
        </Modal.Header>
        <p htmlFor="" style={{
          margin:"20px",
          marginBottom:"10px",
          marginTop:"-10px"
        }} >
            You haven't completed your tasks. Let us know why!
            </p>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Body>
          <div className="row create-workflow-topbody pb-4" style={{ maxHeight: '600px', overflow: 'scroll', scrollbarWidth: 'none' }}>
            {
             
              <>
                
            
           
          
          

                <div className="col-lg-6 mt-4">
                  
                  <div>
                    <div>
                      <input type="checkbox"
                        checked={state.allocatableToBranch}
                        onChange={(e) => handleChange('allocatableToBranch', e.target.checked)}
                      />
                      <span className="ml-4">Was on other task</span>
                    </div>

                    <div>
                      <input type="checkbox"
                        checked={state.recheckRequired}
                        onChange={(e) => handleChange('recheckRequired', e.target.checked)} />
                      <span className="ml-4">Had technical issue</span>
                    </div>

                    <div>
                      <input type="checkbox"
                        checked={state.recurring}
                        onChange={(e) => handleChange('recurring', e.target.checked)} />
                      <span className="ml-4">Need more information</span>
                    </div>

                    <div>
                      <input type="checkbox"
                        checked={state.negotiable}
                        onChange={(e) => handleChange('negotiable', e.target.checked)} />
                      <span className="ml-4">Waiting on someone else?</span>
                    </div> 

                    <div>
                      <input type="checkbox"
                        checked={state.negotiable}
                        onChange={(e) => handleChange('negotiable', e.target.checked)} />
                      <span className="ml-4">Waiting for inputs</span>
                    </div>
                    {
                      isValided && !state.negotiable && !state.recheckRequired && !state.recurring && !state.allocatableToBranch && <div className="text-danger">Please Select Any One</div>
                    }
                  </div>
                </div>
              </>

            }
      

          </div>
        </Modal.Body>
        <div className="hr-line-modal-1">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Footer style={{
          display:"flex"
        }}>
          <div>
           
            {
             
              <button className="AddQuestion_submit_btn mx-3">Submit Reason</button>
            }
       


          </div>
        </Modal.Footer>
      </Modal> */}
      </div>
    </>
  );
};

export default Dashboard;
