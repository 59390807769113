import { useEffect, useState } from "react";
import ApiConfig from "../api/ApiConfig";
import { multipartPostCall, simpleGetCallWithErrorResponse } from "../api/ApiServices";



const textField = ['text', 'number', 'email', 'password', 'textarea']
function DynamicField(props) {


    const {
        type = 'text',
        label,
        placeholder,
        required,
        keyName,
        value = '',
        handleAddTask,
        taskId,
        isValided,
        validations,
        endpoint,
        slection,
        isReject,
        setIsReject
    } = props

    const [employees, setEmployees] = useState([])




    const uplaodFile = (file) => {


        const formData = new FormData();
        formData.append("Files", file);

        multipartPostCall(ApiConfig.UPLOAD_FILE + taskId + '/upload', formData).then((res) => {
            console.log(res?.data);
            handleAddTask(keyName, res?.data[0]?.filePath)
        })

    }

    const getData = () => {

        if (type == 'assign') {

            simpleGetCallWithErrorResponse(ApiConfig?.ACTIVE_EMPLOYEE_LIST).then((res) => {

                setEmployees(res?.json?.Data)

            })

        }

    }

    useEffect(() => {

        getData()

    }, [])



    return <div className="mt-2 mb-2">
        {
            textField.includes(type) && <div>
                <label htmlFor="" className="text-grey">
                    {label} {required != 'None' && <span className="text-danger fw-bold">*</span>}
                </label>
                <input
                    placeholder={placeholder}
                    type={type}
                    className="form-control tasKCategory1"
                    required={required}
                    value={value}
                    onChange={(e) => handleAddTask(keyName, e.target.value)}
                />
                <p className="text-danger">{(required != 'None' && isValided) && validations[keyName + '']}</p>
            </div>

        }

        {
            type == 'datepicker' && <div>
                <label htmlFor="" className="text-grey">
                    {label} {required != 'None' && <span className="text-danger fw-bold">*</span>}
                </label>
                <input
                    placeholder={placeholder}
                    type={'date'}
                    className="form-control tasKCategory1"
                    required={required}
                    value={value}
                    onChange={(e) => handleAddTask(keyName, e.target.value)}
                />
                <p className="text-danger">{(required != 'None' && isValided) && validations[keyName + '']}</p>
            </div>
        }



        {
            type == 'checkbox' && <div>
                <input type="checkbox"
                // checked={actionData?.isReasonRequired}
                // onChange={(e) => handleChangeAction('isReasonRequired', e.target.checked)}
                />
                <label htmlFor="" className="text-grey me-5 ml-1">
                    Is Reason Required ?
                </label>
            </div>
        }

        {
            type == 'assign' && <div>
                <label htmlFor="" className="text-grey">
                    {label} {required != 'None' && <span className="text-danger fw-bold">*</span>}
                </label>
                <select
                    class="form-select tasKCategory1"
                    aria-label="Default select example"
                    value={value}
                    onChange={(e) => handleAddTask(keyName, e.target.value)}
                >
                    <option value={''} disabled>{label}</option>
                    {
                        employees?.map((emp) => {
                            return <option value={emp?.EmployeeId} >{emp?.EmployeeName}</option>
                        })
                    }
                </select>
                <p className="text-danger">{(required != 'None' && isValided) && validations[keyName + '']}</p>
            </div>
        }

        {
            type == 'selection' && <div>
                <label htmlFor="" className="text-grey">
                    {label} {required != 'None' && <span className="text-danger fw-bold">*</span>}
                </label>
                <select
                    class="form-select tasKCategory1"
                    aria-label="Default select example"
                    value={value}
                    onChange={(e) => handleAddTask(keyName, e.target.value)}
                >
                    <option value={''} disabled>{label}</option>
                    {
                        slection?.map((emp) => {
                            return <option value={emp?.value} >{emp?.name}</option>
                        })
                    }
                </select>
                <p className="text-danger">{(required != 'None' && isValided) && validations[keyName + '']}</p>
            </div>
        }

        {
            type == 'attachment' &&
            <div >
                <label htmlFor="">{label} {required != 'None' && <span className="text-danger fw-bold">*</span>}</label> <br />
                <input type="file"
                    style={{ marginTop: '3px' }}
                    onChange={(e) => uplaodFile(e.target.files[0])}
                />
                <p className="text-danger">{(required === 'Required' && isValided) && validations[keyName + '']}</p>
            </div>
        }
        {
            type == 'approve' && <div className="mt-5">
                <input type="checkbox"
                    onChange={(e) => {

                        handleAddTask(keyName, e.target.checked ? type : '')

                    }}
                />
                <label htmlFor="" className="text-grey me-5 ml-3">
                    {label}
                </label>
            </div>
        }
        {
            (type == 'reject') && <div className="mt-5">
                <input type="checkbox"
                    checked={isReject}
                    onChange={(e) => {

                        setIsReject(e.target.checked)

                        handleAddTask(keyName, "")

                    }}
                />
                <label htmlFor="" className="text-grey me-5 ml-3">
                    {label}
                </label>
                <div>

                    {
                        isReject && <div>
                            <label htmlFor="" className="text-grey">
                                Please Enter Reason {required != 'None' && <span className="text-danger fw-bold">*</span>}
                            </label>
                            <input
                                placeholder={placeholder}
                                type={type}
                                className="form-control tasKCategory1"
                                required={required}
                                value={value}
                                onChange={(e) => handleAddTask(keyName, e.target.value)}
                            />
                            <p className="text-danger">{(required != 'None' && isValided) && validations[keyName + '']}</p>
                        </div>
                    }
                </div>
            </div>
        }



        {/* 
        <p className="text-danger">{isAddActionValided && !actionData?.name && 'Please Add Action Name'}</p> */}
    </div>

}

export default DynamicField