import React, { useContext, useEffect, useState } from 'react'
import ProgressCircles from './progressCircles';
import { simpleGetCallWithErrorResponse, simpleGetCallWithToken } from '../../api/ApiServices';
import ApiConfig from '../../api/ApiConfig';
import { AppContext } from '../../context/AppContext';
import { Table, Button } from 'react-bootstrap';
import { ToastMsg } from '../TostMsg';
import NoData from '../../sharedComponent/NoData';
import Loader from '../Loader';


const Workflowdeshboard = () => {
    const { sidebar, userData, setOpenSessionLogoutModal, permissions } =
        useContext(AppContext);
    const [timesheetCounts, setTimesheetCounts] = useState({});

    const [loading, setLoading] = useState(false)



    const getTimesheetCounts = () => {
        setLoading(true)
        const userId = localStorage.getItem("userId")
        simpleGetCallWithToken(ApiConfig.GET_WORKFLOW_DESHBOARD + userId)
            .then((res) => {
                if (res.success === true) {
                    setLoading(false)

                    setTimesheetCounts(res?.data);
                } else {
                    console.log("error response,", res);
                    setLoading(false)
 
                }
            })
            .catch((error) => {
                // console.log("error,", error);
            });
    };

    const getWorkFlowTask = () => {
        // setLoading(true)
        const userId = localStorage.getItem("userId")
        simpleGetCallWithToken(ApiConfig.GET_WORKFLOW_DESHBOARD + userId)
            .then((res) => {
                if (res.success === true) {
                    // setLoading(false)

                    setTimesheetCounts(res?.data);
                } else {
                    console.log("error response,", res);
                }
            })
            .catch((error) => {
                // console.log("error,", error);
            });
    };

    useEffect(() => {
        getTimesheetCounts()
    }, [])

    const onResumData = (item) => {
        const taskId = item.id
        // setLoding(true)
        simpleGetCallWithErrorResponse(ApiConfig.RESUME_WORKFLOW + taskId).then((res) => {
            //   setLoding(false)

            if (res?.json?.success === true) {
                ToastMsg("success", res?.json?.message)
                getWorkFlowTask()
            } else {
                ToastMsg("error", res?.json?.message)
            }

        })
    }

    return (
        <>
            <div
                className={sidebar ? "taskMain " : "cx-active taskMain"}
                id="cx-main"
            >
                {
                    loading?(<div>
                        <Loader/>
                    </div>):(
<>
              
                <div
                    className="col-lg-12 "
                >
                    <ProgressCircles
                        timesheetCounts={timesheetCounts}
                        timesheetCounts2={timesheetCounts}
                        timesheetCounts4={timesheetCounts}
                    />
                </div>

                <div className='mt-10' style={{
                    marginTop: "20px"
                }}>
                    <div className="timesheet-progress-wrapper">
                        <div >
                            <h5 className="mt-2">Completed Workflow</h5>
                        </div>
                        <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
                            {/* <div style={{ maxWidth: '1500px', margin: '0 auto', border: '1px solid #dee2e6', borderRadius: '8px', overflow: 'hidden' }}> */}
                            {/* <div style={{ maxHeight: '300px', overflowY: 'auto' }}> */}
                            <Table responsive style={{ borderCollapse: 'collapse', width: '100%' }}>
                                <thead style={{ position: 'sticky', top: '0', backgroundColor: '#f8f9fa', zIndex: '1' }}>
                                    <tr >
                                        <th>Sr no.</th>
                                        <th>Workflow Name</th>
                                        <th>Completed On</th>
                                        <th>Triggered By</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {timesheetCounts?.completedWorkFlowLists?.length > 0 ? (
                                        timesheetCounts?.completedWorkFlowLists?.map((workflow, index) => (
                                            <tr key={workflow.id}>
                                                <td>{index + 1}</td>
                                                <td>{workflow.name}</td>
                                                <td>{workflow.createdOn}</td>
                                                <td>{workflow.triggerby}</td>
                                                <td>{workflow.status === "Pending" ? (
                                                    <span className="colorP">{workflow.status}</span>
                                                ) : workflow.status === "Allocated" ? (
                                                    <span className="colorAl">{workflow.status}</span>
                                                ) : workflow.status === "Completed" ? (
                                                    <span className="colorC">{workflow.status}</span>
                                                ) : workflow.status === "Deallocated" ? (
                                                    <span className="colorDe">{workflow.status}</span>
                                                ) : workflow.status === "Abort" ? (
                                                    <span className="colorAb">{workflow.status}</span>
                                                ) : workflow.status === "InProgress" ? (
                                                    <span className="colorIn">{workflow.status}</span>
                                                ) : workflow.status === "Rejected" ? (
                                                    <span className="colorR">{workflow.status}</span>
                                                ) : workflow.status === "Reallocated" ? (
                                                    <span className="colorRe">{workflow.status}</span>
                                                ) : workflow.status === "Hold" ? (
                                                    <span className="colorH">{workflow.status}</span>
                                                ) : workflow.status === "Approved" ? (
                                                    <span className="colorA"></span>
                                                ) : (
                                                    ""
                                                )}</td>
                                                <td className="mainBtnsSub">
                                                    {/* <div > */}
                                                    <button className="btn-width saveBtn"
                                                        onClick={() => onResumData(workflow)}
                                                    >Resume</button>
                                                    {/* </div> */}
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr className="ErrorDataNotFound">
                                            <td colSpan={9}>
                                                <NoData />
                                            </td>
                                        </tr>
                                    )

                                    }
                                </tbody>
                            </Table>
                            {/* </div> */}
                            {/* </div> */}
                        </div>
                    </div>
                </div>


                <div className='row'>
                    <div
                        className="col-lg-7 "
                    >
                        <div className='mt-10' style={{
                            marginTop: "20px"
                        }}>
                            <div className="timesheet-progress-wrapper">
                                <div className="">
                                    <h5 className="mt-2">Execution</h5>

                                </div>
                                <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
                                    <Table responsive style={{ borderCollapse: 'collapse', width: '100%' }}>
                                        <thead>
                                            <tr style={{ borderBottom: '2px solid #dee2e6' }}>
                                                <th>Sr no.</th>
                                                <th>Workflow Name</th>
                                                <th>Step Name</th>
                                                <th>Status</th>
                                                {/* <th>Action</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {timesheetCounts?.executedWorkFlowLists?.length > 0 ? (
                                                timesheetCounts?.executedWorkFlowLists?.map((workflow, index) => (
                                                    <tr key={workflow.id}>
                                                        <td>{index + 1}</td>
                                                        <td>{workflow.name}</td>
                                                        <td>{workflow.taskName}</td>
                                                        <td>{workflow.status === "Suspended" ? (
                                                            <span className="colorP">{workflow.status}</span>
                                                        ) : workflow.status === "Allocated" ? (
                                                            <span className="colorAl">{workflow.status}</span>
                                                        ) : workflow.status === "Completed" ? (
                                                            <span className="colorC">{workflow.status}</span>
                                                        ) : workflow.status === "Deallocated" ? (
                                                            <span className="colorDe">{workflow.status}</span>
                                                        ) : workflow.status === "Abort" ? (
                                                            <span className="colorAb">{workflow.status}</span>
                                                        ) : workflow.status === "InProgress" ? (
                                                            <span className="colorIn">{workflow.status}</span>
                                                        ) : workflow.status === "Rejected" ? (
                                                            <span className="colorR">{workflow.status}</span>
                                                        ) : workflow.status === "Reallocated" ? (
                                                            <span className="colorRe">{workflow.status}</span>
                                                        ) : workflow.status === "Hold" ? (
                                                            <span className="colorH">{workflow.status}</span>
                                                        ) : workflow.status === "Approved" ? (
                                                            <span className="colorA"></span>
                                                        ) : (
                                                            ""
                                                        )}</td>
                                                        {/* <td className="mainBtnsSub">
                                                        <div >
                                                        <button className="btn-width saveBtn"
                                                        >Resume</button>
                                                        </div>
                                                    </td> */}
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr className="ErrorDataNotFound">
                                                    <td colSpan={9}>
                                                        <NoData />
                                                    </td>
                                                </tr>
                                            )
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="col-lg-5 "
                    >
                        <div className='mt-10' style={{
                            marginTop: "20px"
                        }}>
                            <div className="timesheet-progress-wrapper">
                                <div >
                                    <h5 className="mt-2">Defined Workflow</h5>

                                </div>
                                <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
                                    <Table responsive style={{ borderCollapse: 'collapse', width: '70%' }}>
                                        <thead>
                                            <tr style={{ borderBottom: '2px solid #dee2e6' }} >
                                                <th>Sr no.</th>
                                                <th>Workflow Name</th>
                                                <th>Status</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {timesheetCounts?.definedWorkFlowLists?.length > 0 ? (
                                                timesheetCounts?.definedWorkFlowLists?.map((workflow, index) => (
                                                    <tr key={workflow.id}>
                                                        <td>{index + 1}</td>

                                                        <td>{workflow.name}</td>
                                                        <td>{workflow.status === "InActive" ? (
                                                            <span className="colorP">{workflow.status}</span>
                                                        ) : workflow.status === "Allocated" ? (
                                                            <span className="colorAl">{workflow.status}</span>
                                                        ) : workflow.status === "Completed" ? (
                                                            <span className="colorC">{workflow.status}</span>
                                                        ) : workflow.status === "Deallocated" ? (
                                                            <span className="colorDe">{workflow.status}</span>
                                                        ) : workflow.status === "Abort" ? (
                                                            <span className="colorAb">{workflow.status}</span>
                                                        ) : workflow.status === "InProgress" ? (
                                                            <span className="colorIn">{workflow.status}</span>
                                                        ) : workflow.status === "Rejected" ? (
                                                            <span className="colorR">{workflow.status}</span>
                                                        ) : workflow.status === "Reallocated" ? (
                                                            <span className="colorRe">{workflow.status}</span>
                                                        ) : workflow.status === "Suspended" ? (
                                                            <span className="colorH">{workflow.status}</span>
                                                        ) : workflow.status === "Approved" ? (
                                                            <span className="colorA"></span>
                                                        ) : (
                                                            ""
                                                        )}</td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr className="ErrorDataNotFound">
                                                    <td colSpan={9}>
                                                        <NoData />
                                                    </td>
                                                </tr>
                                            )
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </>
      )
    }
            </div>


        </>
    )
}

export default Workflowdeshboard