import BarChart from "../../component/Barchart.js";
import React, { useContext, useEffect, useState } from "react";
import deleteicon from "../../assets/images/delete_icon_gray.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import searchicon from "../../assets/images/ic_search.svg";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { simpleGetCallWithToken } from "../../api/ApiServices.js";
import ApiConfig from "../../api/ApiConfig.js";
import NoData from "../../sharedComponent/NoData.js";
import Loader from "../Loader.js";
import Pagination from "../../sharedComponent/Pagination.js";

const AgentcallList = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);
  const [agentList, SetAgentList] = useState([])
  const [loading, setLoading] = useState(false)

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const dataArray = [
    { id: 1, name: "Call", Count: "23232" },
    { id: 2, name: "Users", Count: "23232" },
    { id: 3, name: "Not Call Classification", Count: "23232" },
    { id: 4, name: "Zero Call List", Count: "23232" },
    { id: 5, name: "Candidates", Count: "23232" },
    { id: 6, name: "Services Request", Count: "23232" },
    { id: 7, name: "Agents", Count: "23232" },
  ];

  const dataArrayLocation = [
    {
      id: 1,
      NAME: "Thushara",
      PRIORITY: "2",

      Action: "Delete",
    },

    {
      id: 2,
      NAME: "Thushara",
      PRIORITY: "2",

      Action: "Delete",
    },
  ];

  const [usersPerPage, setUsersPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [filterSerchName, setFilterSerchName] = useState([])
  const [selectedRows, setSelectedRows] = useState(10);

  const itemsPerPage = 5;

  // Calculate the displayed items
  const startIndex = currentPage * usersPerPage;
  const endIndex = startIndex + parseInt(usersPerPage);
  // const currentItems = dataArrayLocation.slice(
  //   indexOfFirstItem,
  //   indexOfLastItem
  // );

  const totalPages = Math.ceil(dataArrayLocation.length / itemsPerPage);

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleSelectChange = (event) => {
    setSelectedRows(event.target.value);
    console.log(`Selected number of rows: ${event.target.value}`);
    // You can add additional logic to handle the row change
  };

  const getcrmCallData = () => {
    setLoading(true)
    simpleGetCallWithToken(ApiConfig.AGENT_PRIORITY)
      .then((res) => {
        setLoading(false)
        console.log("Call List Data>>>", res)
        SetAgentList(res.objs)
      })
      .catch((err) => {
        setLoading(false)

        console.log("call List Data>>>", err)
      })
  }

  useEffect(() => {
    getcrmCallData()
  }, [])

    useEffect(() => {
      // Otherwise, use location_list
  
      const filteredData = agentList?.filter((item) =>
        item?.name?.toLowerCase().includes(searchValue.toLowerCase())
      );
  
      console.log("Filtered Data>>", filteredData);
      setFilterSerchName(filteredData); // Set the filtered data in the state
  
    }, [agentList, searchValue,]);
  

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="sub-header-role ">
          <h6 className="mt-2">Agent's List And There Priority</h6>
          <Link to={"/UserdetailsAgent"}>
          <button className="custoBtnForCrmCall">
            {/* <a className=" textcolor" href="UserdetailsAgent"> */}
              Add Priority
            {/* </a> */}
          </button>
          </Link>
        </div>

        <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
          <div className="search-input-wrapper">
            <input type="text"
              placeholder="Search here"
              onChange={(e) => {
                setSearchValue(e.target.value);
                setCurrentPage(0);
              }}
            />

            {Dark === "lightMode" ? (
              <img src={searchicon} alt="" />
            ) : (
              <img src={ic_search_dark} alt="" />
            )}
          </div>

          <div className="entries-wrapper">
            <p>Entries per page</p>
            <select
                className="form-select select-drop-icon"
                aria-label="Default select example"
                // value={selectedRows}
                onChange={(e) => {
                  setUsersPerPage(e.target.value);
                  setCurrentPage(0);
                }}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value={25} selected>25</option>
                {/* <option value={50}>50</option>
                <option value={100}>100</option> */}
              </select>
          </div>
        </div>
        <div class="table-wrapper table-wrapper-2">
          <table className="">
            <thead className="firstTabelRow">
              <tr classname="row-hover modal-footer-table-body-tr">
                <th>NAME</th>
                <th>PRIORITY</th>
                <th>DELETE</th>
              </tr>
            </thead>
            <tbody>
              {
     loading ? (
      <tr>
        <td colSpan="9" className="text-center">
          <Loader />
        </td>
      </tr>
    ) : (
      agentList?.length>0?(
                filterSerchName.slice(startIndex, endIndex).map((item) => (
                  <tr key={item.id} className="table-row-custom">
                    <td>{item.name}</td>
                    <td>{item.agent_priority}</td>

                    <td>
                      <Link to="" onClick={handleShow3}>
                        <img
                          src={deleteicon}
                          alt="Image 2"
                          className="cell-image "
                        />
                      </Link>
                    </td>
                  </tr>
                ))
              ):(
                <tr className="ErrorDataNotFound">
                <td colSpan={9}>
                  <NoData  />
                </td>
              </tr>
              )
            )
            }
            </tbody>
          </table>
          <Pagination
            data={filterSerchName}
            pageChangeHandler={pageChangeHandler}
            usersPerPage={usersPerPage}
            currentPage={currentPage}
            searchValue={searchValue}
            searchedDataPageCounts={filterSerchName}
          />
        </div>
      </div>

      <Modal
        className="delete-news-modal"
        show={show3}
        onHide={handleClose3}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="delete-title">
            Delete Confirmation
          </Modal.Title>
        </Modal.Header>
        <div class="lineSeperate"></div>
        <Modal.Body className="delete-body ps-4">
          <p>Are you sure you want to delete this Agent List ?</p>
        </Modal.Body>

        <div className="lineSeperate"></div>
        <div className="d-flex justify-content-end align-items-center mainBtnsSub">
          <button className="btn-width cancelBtn mx-3" onClick={handleClose3}>
            Cancel
          </button>
          <button
            className="btn-width saveBtn"
            onClick={() => {
              handleClose3();
            }}
          >
            Delete
          </button>
        </div>
      </Modal>
    </>
  );
};

export default AgentcallList;
