import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { useNavigate, useParams } from "react-router-dom";
import { PostCallWithErrorResponse, simpleGetCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { ToastMsg } from "../TostMsg";
import { Accordion } from "react-bootstrap";
import DynamicField from "../../sharedComponent/dynamicField";
import CommonButton from "../ChatAdmin/button";
import { emailValidation } from "../../common";
import Modal from "react-bootstrap/Modal";
import close from "../../assets/images/close.svg";

function CompleteTaskDynamic() {
    const { sidebar, setSidebar, Dark, setDark, userData, permissions, setLoadWorkFlowTaskForm } =
        useContext(AppContext);

    const { id, taskAllocationId } = useParams()

    const navigate = useNavigate()

    const [valudationMessage, setValidationMessage] = useState({})
    const [isLoading, setIsLoading] = useState(false)

    const [isReject, setIsReject] = useState(false)
    const [isUpdateStatusOpne, setiSUpadateStatusOpen] = useState(false)
    const [inprogressId, setInProgressId] = useState(0)


    const initialPayload = {
        "taskId": '',
        "taskAllocationId": '',
        "data": [

            {
                "key": "resion",
                "value": ""
            },
            {
                "key": "action",
                "value": ""
            }
        ],
        "action": "",
        "resion": "",
        Reason: '',
        "isAdministrative": false
    }

    const [payload, setPayload] = useState(initialPayload)

    const [action, setAction] = useState()

    const [isValided, setIsvalided] = useState(false)

    const [taskDeatils, setTaskDetails] = useState({})
    const [dynamicFields, setDynamicsFields] = useState([])

    const getDynamicFields = () => {


        PostCallWithErrorResponse(ApiConfig.GET_DYNAMIC_FIELDS_FOR_TASK, JSON.stringify({
            "Id": Number(id),
            "IsAdministrative": false
        })).then((res) => {

            if (res?.json?.success) {
                setDynamicsFields(res?.json?.data)
                let defaultId = res?.json?.data[0]?.taskActions?.filter((i) => i?.statusName === "In Progress")



                setInProgressId(defaultId[0]?.id)

                handleAddAction(defaultId[0]?.id)

                setPayload(prev => ({
                    ...prev,
                    action: defaultId[0]?.id + ''
                }))
            }

        })

    }

    const getTaskDetails = () => {

        simpleGetCallWithErrorResponse(ApiConfig.GET_TASK_DETAILS + id).then((res) => {

            if (res?.json?.success) {
                setTaskDetails(res?.json?.data)
            } else {
                ToastMsg('error', res?.json?.message)
            }

        })

    }

    useEffect(() => {

        getDynamicFields()

        getTaskDetails()

    }, [])

    const handleAddTask = (key, val) => {

        console.log(key, val);

        let indexOf = payload?.data?.findIndex((i, index) => {
            return i.key == key
        })

        if (indexOf != -1) {

            let updateData = [...payload?.data]

            updateData[indexOf].value = val + ''


            setPayload((prev) => ({
                ...prev,
                data: updateData
            }))


        } else {

            let updateData = [...payload?.data, {
                'key': key,
                value: val
            }]

            setPayload(prev => ({
                ...prev,
                data: updateData
            }))

        }

    }

    const handleAddAction = (id) => {

        let indexOf = payload?.data?.findIndex((i, index) => {
            return i.key == 'action'
        })
        let updateData = [...payload?.data]

        updateData[indexOf].value = id + ''

        let isValid = true;

        setPayload(prev => ({
            ...prev,
            'action': "" + id,
            data: updateData
        }))

    }



    const handleSubmit = () => {

        if (!payload?.action) {
            ToastMsg('error', 'please Select Action')
            return
        }




        let isValid = true;

        let allValidationMesg = {
            ...valudationMessage
        }

        dynamicFields[0]?.taskDataToUIDtos?.forEach((i) => {



            if (i.validations[0]?.name != 'None') {


                let findStoreValue = payload?.data?.filter((val) => val.key == i?.id);
                let validation = i?.validations[0]?.name;
                let val = findStoreValue[0]?.value;
                let ifAny = i?.validations[0]?.validationifany;
                let isRejectField = i?.validations[0]?.value === "Reject"

                let regex = new RegExp(ifAny);



                if (findStoreValue?.length === 0) {

                    if (!isRejectField) {
                        allValidationMesg[i?.id + ''] = i?.description
                        isValid = false
                        setIsvalided(true)
                    }



                } else {

                    if (isRejectField && isReject && validation == 'Required' && val.length == 0) {
                        isValid = false
                        allValidationMesg[i?.id + ''] = i?.description
                        setIsvalided(true)


                    } else if (!isRejectField && validation == 'Required' && val.length == 0) {
                        alert('')

                        isValid = false
                        allValidationMesg[i?.id + ''] = i?.description
                        setIsvalided(true)
                    }
                    else if (validation == "Email" && !emailValidation(val)) {

                        isValid = false
                        allValidationMesg[i?.id + ''] = i?.description
                        setIsvalided(true)
                    } else if (validation == "MaxLength" && val.length > Number(ifAny)) {

                        isValid = false
                        allValidationMesg[i?.id + ''] = i?.description
                        setIsvalided(true)
                    } else if (validation == "MinLength" && val.length < Number(ifAny)) {

                        isValid = false
                        allValidationMesg[i?.id + ''] = i?.description
                        setIsvalided(true)
                    } else if (validation == "Max" && (isNaN(val) || Number(val) > Number(ifAny))) {

                        isValid = false
                        allValidationMesg[i?.id + ''] = i?.description
                        setIsvalided(true)
                    } else if (validation == "Min" && (isNaN(val) || Number(val) < Number(ifAny))) {

                        isValid = false
                        allValidationMesg[i?.id + ''] = i?.description
                        setIsvalided(true)
                    } else if (validation == "Pattern" && !regex.test(val)) {

                        isValid = false
                        allValidationMesg[i?.id + ''] = i?.description
                        setIsvalided(true)
                    } else {
                        allValidationMesg[i?.id + ''] = ''
                    }


                }





            }

        })

        if (payload?.action != inprogressId && !payload?.Reason) {
            ToastMsg('error', 'Please Enter Reason')

            return
        }


        setValidationMessage(allValidationMesg)
        if (isValid) {

            setIsLoading(true)

            payload.taskId = Number(taskAllocationId)

            payload.taskAllocationId = Number(id);



            PostCallWithErrorResponse(ApiConfig?.SUBMIT_DYNAMIC_TASK, JSON.stringify(payload)).then((res) => {

                if (res?.json?.success) {
                    ToastMsg('success', res?.json?.message)
                    navigate('/CompleteTask')
                    setLoadWorkFlowTaskForm(true)
                } else {
                    ToastMsg('error', res?.json?.message)
                }

                setIsLoading(false)

            })

        }



    }

    return (
        <>
            <div
                className={sidebar ? "taskMain " : "cx-active taskMain"}
                id="cx-main"
            >

                <div className="role-content">
                    <div className="sub-header-role ">
                        <h6 className="mt-2">Workflow Task Form</h6>
                        <div className="d-flex  justify-content-center align-items-center">
                            <h5>Status : {taskDeatils?.status}</h5>
                            <button
                                className="ml-4 modal-create-btn branchList-modal-cancel-btn-arbaz-responsive" style={{ cursor: 'pointer' }}
                                onClick={() => setiSUpadateStatusOpen(true)}
                            >Change status</button>
                        </div>
                    </div>

                    <div className='bg-white p-3 rounded-3 mt-4'>
                        <div className="mt-4 row mainFormSection box-shadow mr-4 p-4">
                            <div className="col-lg-4 d-flex justify-content-between">
                                <h5 className="text-muted">Workflow Name : </h5>
                                <h5 className="fw-bold">{taskDeatils?.workFlowName}</h5>
                            </div>
                            <div className="col-lg-4 d-flex justify-content-between">
                                <h5 className="text-muted">Sequence No : </h5>
                                <h5 className="fw-bold">{taskDeatils?.sequenceNumber}</h5>
                            </div>
                            <div className="col-lg-4 d-flex justify-content-between">
                                <h5 className="text-muted">Date : </h5>
                                <h5 className="fw-bold">{taskDeatils?.date}</h5>
                            </div>
                            <div className="col-lg-4 d-flex justify-content-between">
                                <h5 className="text-muted">Description : </h5>
                                <h5 className="fw-bold">{taskDeatils?.description}</h5>
                            </div>
                            <div className="col-lg-4 d-flex justify-content-between">
                                <h5 className="text-muted">Display Type : </h5>
                                <h5 className="fw-bold">{taskDeatils?.displayType}</h5>
                            </div>
                            <div className="col-lg-4 d-flex justify-content-between">
                                <h5 className="text-muted">Subscription : </h5>
                                <h5 className="fw-bold">{taskDeatils?.subscription}</h5>
                            </div>
                            <div className="col-lg-4 d-flex justify-content-between">
                                <h5 className="text-muted">Service : </h5>
                                <h5 className="fw-bold">{taskDeatils?.service}</h5>
                            </div>
                            <div className="col-lg-4 d-flex justify-content-between">
                                <h5 className="text-muted">Quotation : </h5>
                                <h5 className="fw-bold">{taskDeatils?.quotation}</h5>
                            </div>
                            <div className="col-lg-4 d-flex justify-content-between">
                                <h5 className="text-muted">Branch Name : </h5>
                                <h5 className="fw-bold">{taskDeatils?.branchName}</h5>
                            </div>
                            <div className="col-lg-4 d-flex justify-content-between">
                                <h5 className="text-muted">Client Name  : </h5>
                                <h5 className="fw-bold">{taskDeatils?.client?.firstName + ' ' + taskDeatils?.client?.lastName}</h5>
                            </div>

                        </div>

                        <Accordion defaultActiveKey={0}>
                            {
                                dynamicFields?.map((task, index) => {

                                    return <Accordion.Item eventKey={index} key={index} className="mt-2">
                                        <Accordion.Header>
                                            <div className="menus-items w-100 d-flex justify-content-between" style={{ backgroundColor: 'whiteSmoke', padding: '15px 15px 15px 15px' }}>
                                                <h5 className=""> {task?.taskDataToUIDtos[0]?.taskName} </h5>

                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <div className="row m-4">
                                                {
                                                    task?.taskDataToUIDtos?.map((field) => {

                                                        return <div className={`${field?.displayTypeId == 1 ? 'col-lg-12' : 'col-lg-6'}`}>
                                                            <DynamicField
                                                                slection={field?.selections}
                                                                label={field?.name}
                                                                placeholder={field?.description}
                                                                required={field?.validations[0]?.name}
                                                                type={field?.dataType?.toLowerCase()}
                                                                keyName={field?.id + ''}
                                                                handleAddTask={handleAddTask}
                                                                value={payload?.data?.filter((i) => i.key == field?.id)[0]?.value}
                                                                taskId={field?.taskId}
                                                                isValided={isValided}
                                                                validations={valudationMessage}
                                                                isReject={isReject}
                                                                setIsReject={setIsReject}
                                                            />
                                                        </div>
                                                    })
                                                }
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                <CommonButton
                                                    title={'Submit'}
                                                    handlerFunction={handleSubmit}
                                                    isLoading={isLoading}
                                                />
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                })
                            }
                        </Accordion>
                    </div>
                </div>
            </div>

            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={isUpdateStatusOpne}
                className="create-workflow-main modal-lg"
                size="md"
            >
                <Modal.Header>
                    <Modal.Title>
                        <label htmlFor="" className="create-role-text">
                            Update Workflow Status
                        </label>
                    </Modal.Title>
                    <img
                        src={close}
                        alt=""
                        onClick={() => setiSUpadateStatusOpen(false)}
                    />
                </Modal.Header>
                <div className="hr-line-modal">
                    <hr style={{ margin: "0" }} />
                </div>

                <Modal.Body>
                    <div className="row">
                        <div className="m col-lg-6">
                            {
                                dynamicFields[0]?.taskActions?.map((action, index) => {
                                    return <div key={index}>
                                        <input
                                            type="radio"
                                            id={`action-${action?.id}`}
                                            name="taskAction"
                                            value={action?.id + ''}
                                            checked={action?.id == payload?.action}
                                            //  defaultChecked={action?.id}
                                            onChange={() => {
                                                handleAddAction(action?.id + '');
                                            }}
                                        />
                                        <label htmlFor={`action-${action?.id}`} className="ml-4 mt-1">{action?.statusName}</label>
                                    </div>
                                })
                            }
                        </div>
                        {
                            payload?.action != inprogressId && <div className="col-lg-6">
                                <label htmlFor="Reason">Reason :</label>
                                <textarea name="" rows="8"
                                    placeholder="Enrer Reason"
                                    class="form-control tasKCategory1"
                                    style={{ height: '100px' }}

                                    value={payload?.Reason}
                                    onChange={(e) => {
                                        setPayload(prev => ({
                                            ...prev,
                                            Reason: e.target.value
                                        }
                                        ))
                                    }}
                                ></textarea>
                            </div>
                        }
                    </div>
                </Modal.Body>
                <div className="hr-line-modal">
                    <hr style={{ margin: "0" }} />
                </div>
                <Modal.Footer>
                    <div>
                        <button className="modal-cancel-btn"
                            onClick={() => setiSUpadateStatusOpen(false)}
                        >
                            Close
                        </button>
                        <button
                            onClick={() => {

                                if (payload.action != inprogressId && !payload?.Reason) {


                                    ToastMsg('error', 'Please Enter Reason')

                                } else {
                                    setiSUpadateStatusOpen(false)
                                }

                            }}
                            className="modal-create-btn branchList-modal-cancel-btn-arbaz-responsive">
                            Submit
                        </button>

                    </div>
                </Modal.Footer>
            </Modal >
        </>
    )
}

export default CompleteTaskDynamic