import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { Badge, Button, Form } from "react-bootstrap";
import { simpleGetCallWithErrorResponse, PostCallWithAuthToken } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { ToastMsg } from "../TostMsg";
import Loader from "../Loader";

const Ivrgetewway = () => {
  const { sidebar } = useContext(AppContext);
  const [smsDrop, setSmsDrop] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isShow, setisShow] = useState(false);
  const [updateIvr, setUpdateIvr] = useState([]);
  console.log("updateIvr",updateIvr);
  
  const [newIvr, setNewIvr] = useState({
    uid: "",
    pin: "",
    is_active: true,
    master_ivr_id: "",
    id:''
  });



  useEffect(() => {
    iverGetDrop();
    smsGetDetails();
  }, []);

  const iverGetDrop = () => {
    simpleGetCallWithErrorResponse(ApiConfig.IvrSeriver)
      .then((res) => {
        if (res.json.success) {
          setSmsDrop(res.json.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const smsGetDetails = () => {
    setLoading(true);
    simpleGetCallWithErrorResponse(ApiConfig.IVRDetalis)
      .then((res) => {
        setLoading(false);
        if (res.json.success) {
          if(res.json.data) {
            setUpdateIvr(res.json.data.sort((a,b)=> b.ivrservice_id - a.ivrservice_id));
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleUpdateIvr = (e, ivrIndex) => {
    e.preventDefault();
    let body = updateIvr[ivrIndex];
    body = {...body, id:body.ivrservice_id, master_ivr_id : body.masterivr_id}
    setLoading(true);

    PostCallWithAuthToken(ApiConfig.AddIvr, JSON.stringify(body))
      .then((res) => {
        setLoading(false);
        if (res.json.success) {
          ToastMsg("success", res.json.message);
          smsGetDetails()
          setisShow(false)
        } else {
          ToastMsg("error",res.json.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleAddNewIvr = (e) => {
    e.preventDefault();
    setLoading(true);

    PostCallWithAuthToken(ApiConfig.AddIvr, JSON.stringify(newIvr))
      .then((res) => {
        console.log("res",res);
        
        setLoading(false);
        if (res.json.success) {
          ToastMsg("success", res.json.message);
          smsGetDetails();
          setisShow(false)
        } else {
          ToastMsg("error",res.json.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <div className={sidebar ? "taskMain" : "cx-active taskMain"} id="cx-main">
      <div className="role-content">
        <div className="sub-header-role">
          <h6 className="mt-2">IVR Service</h6>
        </div>
        <div className="my-3 d-flex justify-content-end">
                  <Button type="submit" className="allocateionBtn" 
                   onClick={() => {
                    setisShow(true)
                    
                  }}


                  >
                   Add New IVR Service
                  </Button>
                </div>




      {/* Show to moda; */} 
      {
  isShow && (
    <div className="innerRoleSec">
      <h6>Add New IVR</h6>
      <Form onSubmit={handleAddNewIvr} >
              <div className="row">
                <Form.Group className="col-md-12 my-3">
                  <div className="">
                    <div className="toggle-switch-enabled">
                      <input
                        type="checkbox"
                        className="toggle-switch-checkbox-enabled"
                        name="yesnoenabled"
                        id={`yesnoenabled-1`}
                        checked={newIvr.is_active}
                        onChange={(e) => setNewIvr({ ...newIvr, is_active: e.target.checked })}
                      />
                      <label className="toggle-switch-label-enabled" htmlFor={`yesnoenabled-1`}>
                        <span className="toggle-switch-inner-enabled" />
                        <span className="toggle-switch-switch-enabled" />
                      </label>
                    </div>
                    <span className="text-grey ms-2">IVR Service Status</span>
                  </div>
                </Form.Group>

                <Form.Group className="col-md-6">
                  <Form.Label className="mb-2 text-grey">IVR Service:</Form.Label>
                  <Form.Select
                    className="form-control tasKCategory1"
                    required
                    value={newIvr.master_ivr_id}
                    onChange={(e) => setNewIvr({ ...newIvr, master_ivr_id: e.target.value })}
                    
                  >
                    <option value={0}>Select</option>
                    {smsDrop.map((item) => (
                      <option key={item.GatewayId} value={item.id}>
                        {item.ivr_name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>

                <Form.Group className="col-md-6 my-auto">
                  <Form.Label className="mb-2 text-grey">UID:</Form.Label>
                  <Form.Control
                    className="form-control tasKCategory1"
                    type="text"
                    value={newIvr.uid}
                    onChange={(e) => setNewIvr({ ...newIvr, uid: e.target.value })}
                    required
                  />
                </Form.Group>

                <Form.Group className="col-md-6 my-3">
                  <Form.Label className="mb-2 text-grey">Pin:</Form.Label>
                  <Form.Control
                    className="form-control tasKCategory1"
                    type="text"
                    value={newIvr.pin}
                    onChange={(e) => setNewIvr({ ...newIvr, pin: e.target.value })}
                    required
                  />
                </Form.Group>

                <div className="my-3 d-flex justify-content-end">
                  <Button type="submit" className="allocateionBtn">
                    Add 
                  </Button>
                </div>
              </div>
            </Form>
    </div>
  )
}









        {updateIvr.map((ivrItem, index) => (
          <div className="innerRoleSec" key={index}>
            <Form onSubmit={(e) => handleUpdateIvr(e, index)}>
              <div className="row">
                <Form.Group className="col-md-12 my-3">
                  <div className="">
                    <div className="toggle-switch-enabled">
                      <input
                        type="checkbox"
                        className="toggle-switch-checkbox-enabled"
                        name="yesnoenabled"
                        id={`yesnoenabled-${index}`}
                        checked={ivrItem.is_active}
                        onChange={(e) => {
                          const updatedList = [...updateIvr];
                          updatedList[index].is_active = e.target.checked;
                          setUpdateIvr(updatedList);
                        }}
                      />
                      <label className="toggle-switch-label-enabled" htmlFor={`yesnoenabled-${index}`}>
                        <span className="toggle-switch-inner-enabled" />
                        <span className="toggle-switch-switch-enabled" />
                      </label>
                    </div>
                    <span className="text-grey ms-2">IVR Service Status</span>
                  </div>
                </Form.Group>

                <Form.Group className="col-md-6">
                  <Form.Label className="mb-2 text-grey">IVR Service:</Form.Label>
                  <Form.Select
                    className="form-control tasKCategory1"
                    required
                    value={ivrItem.masterivr_id}
                    onChange={(e) => {
                      const updatedList = [...updateIvr];
                      updatedList[index].masterivr_id = e.target.value;
                      setUpdateIvr(updatedList);
                    }}
                  >
                    <option value={0}>Select</option>
                    {smsDrop.map((item) => (
                      <option key={item.GatewayId} value={item.id}>
                        {item.ivr_name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>

                <Form.Group className="col-md-6 my-auto">
                  <Form.Label className="mb-2 text-grey">UID:</Form.Label>
                  <Form.Control
                    className="form-control tasKCategory1"
                    type="text"
                    value={ivrItem.uid}
                    onChange={(e) => {
                      const updatedList = [...updateIvr];
                      updatedList[index].uid = e.target.value;
                      setUpdateIvr(updatedList);
                    }}
                  />
                </Form.Group>

                <Form.Group className="col-md-6 my-3">
                  <Form.Label className="mb-2 text-grey">Pin:</Form.Label>
                  <Form.Control
                    className="form-control tasKCategory1"
                    type="text"
                    value={ivrItem.pin}
                    onChange={(e) => {
                      const updatedList = [...updateIvr];
                      updatedList[index].pin = e.target.value;
                      setUpdateIvr(updatedList);
                    }}
                  />
                </Form.Group>

                <div className="my-3 d-flex justify-content-end">
                  <Button type="submit" className="allocateionBtn">
                    Update IVR
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        ))}
      </div>




















    </div>
  );
};

export default Ivrgetewway;
